export default function FireIcon({
  size = 24,
  color = "#333333",
  marginBottom,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 47 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom }}
      {...props}
    >
      <path
        d="M32.9529 5.92087C31.3461 4.55911 29.6618 3.12787 27.9468 1.568C26.6311 0.371519 24.8543 -0.182985 23.0916 0.0531149C21.3835 0.278783 19.8638 1.25103 18.943 2.70725C16.5093 6.77425 14.7885 11.2272 13.8551 15.874C13.4889 15.3451 13.164 14.7889 12.8833 14.21C11.9163 12.1727 9.48083 11.3049 7.4435 12.2719C6.95025 12.506 6.50896 12.8367 6.14577 13.2443C2.72123 16.7437 0.822763 21.4576 0.866021 26.3538C0.768691 36.6449 7.69099 45.6803 17.6526 48.265C19.5061 48.7314 21.4091 48.9726 23.3203 48.9836C23.3815 48.9836 24.0328 48.9775 24.2962 48.9591C36.4849 48.5644 46.1572 38.5612 46.142 26.366C46.1339 17.1174 40.0253 11.9315 32.9529 5.92087ZM23.2693 44.886C21.7498 44.7704 20.2482 44.1549 19.1145 43.2404C17.3392 41.9842 16.1645 40.1421 15.8846 38.0199C15.5375 34.7083 17.5792 30.3779 21.7504 25.4861C22.1877 24.9753 22.8276 24.6825 23.5001 24.6858C24.1639 24.681 24.7952 24.973 25.2212 25.482C29.0432 30.0186 31.1421 34.204 31.1421 37.2685C31.1303 41.2948 27.9833 44.4798 23.9758 44.8676C23.7998 44.8847 23.5 44.9105 23.2693 44.886ZM34.8844 41.0028C34.7639 41.0966 34.6312 41.1722 34.5087 41.262C34.9798 39.9817 35.2224 38.6287 35.2254 37.2644C35.2254 32.1051 31.4789 26.5763 28.3388 22.8462C27.1375 21.4233 25.3704 20.6018 23.5082 20.6003H23.5C21.6341 20.5969 19.8606 21.4128 18.649 22.8318C13.6571 28.6792 11.3644 33.9323 11.8339 38.4464C11.9454 39.4944 12.2131 40.5197 12.6281 41.4885C7.78 37.9757 4.92336 32.3406 4.95551 26.3537C4.91608 22.4644 6.44982 18.7241 9.20827 15.982C9.67923 16.9611 10.2447 17.8917 10.8968 18.7608C11.7923 19.9714 13.3221 20.5332 14.7881 20.1899C16.2829 19.8593 17.4435 18.6803 17.7506 17.1805C18.5709 12.8448 20.1384 8.68458 22.3831 4.88564C22.6593 4.45134 23.1156 4.16366 23.6265 4.10164C24.1976 4.02479 24.7732 4.205 25.1986 4.59365C26.9504 6.18615 28.6695 7.65615 30.3028 9.03839C37.1179 14.8265 42.0444 19.0079 42.0444 26.366C42.062 32.0922 39.4187 37.5018 34.8904 41.0068L34.8844 41.0028Z"
        fill={color}
      />
    </svg>
  );
}
