import React from "react";
import SPText from "../../../SPText/SPText";
import Badge from "../../../Badge/Badge";
import styles from "./Sports.module.css";

export default function Sports({ sports }) {
  return (
    <div className={styles.container}>
      <SPText variant="h3">Sportok</SPText>
      <div className={styles.sports}>
        {sports &&
          sports.map((sport) => (
            <Badge
              key={sport.id}
              Component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <img
                    src={sport.icon}
                    style={{ width: 18, height: 18 }}
                    alt=""
                  />
                  <SPText variant="comment-text">{sport.name}</SPText>
                </div>
              }
              color="#FFF"
              textColor="#333"
              variant="long"
            />
          ))}
      </div>
    </div>
  );
}
