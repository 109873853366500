import React from "react";
import PropTypes from "prop-types";
import SPText from "../SPText/SPText";
import styles from "./SPTable.module.css";
import PageSelector from "../PageSelector/PageSelector";

const _ = require("lodash");

const getMultiple = (obj, paths) => {
  const values = paths
    .map((path) => _.get(obj, path))
    .filter((v) => !_.isUndefined(v));

  return values.length ? values : "";
};

export default function SPTable({
  items,
  meta,
  onPageChange,
  columns,
  Action,
  hasBorders = true,
}) {
  const { page, pageTotal } = meta;
  return (
    <div className={styles.container}>
      <table className={`${styles.table} ${hasBorders ? styles.border : ""}`}>
        <thead>
          <tr className={`${hasBorders ? styles.border : ""}`}>
            {columns.map(({ title }) => (
              <th key={title}>
                <SPText variant="button-name">{title}</SPText>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item) => (
              <tr
                className={`${hasBorders ? styles.border : ""}`}
                key={item.id}
              >
                {columns.map(({ field, Component }) => {
                  const column =
                    typeof field !== "string"
                      ? getMultiple(item, field)
                      : _.get(item, field);
                  return (
                    <td key={field}>
                      {Component ? (
                        <Component column={column} />
                      ) : (
                        <SPText variant="selection-text">{column}</SPText>
                      )}
                    </td>
                  );
                })}
                {Action && (
                  <td>
                    <Action item={item} />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <PageSelector
        key={meta.total}
        page={page + 1}
        maxPage={pageTotal}
        onChange={onPageChange}
      />
    </div>
  );
}

SPTable.propTypes = {
  items: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string, // title of the column
      field: PropTypes.any, // field or fields of the data in the column
      Component: PropTypes.func, // ({item, ...props})=><Component item={item}/>
    })
  ),
  Action: PropTypes.func, // ({item, ...props})=><Action item={item}/>
};
