import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  SPButton,
  SPInput,
  SPModal,
  SPRadioButtons,
  SPTable,
  SPText,
} from "../../components";
import { ArrowSmallRight, SearchIcon, TrashIcon } from "../../components/icons";
import useEvent from "../../hooks/useEvent";
import useEvents from "../../hooks/useEvents";
import useQuery from "../../hooks/useQuery";
import useToast from "../../hooks/useToast";
import styles from "./EventsPage.module.css";

const DeleteEventModal = ({ visible, event, onCancel, onSuccess, onError }) => {
  const { deleteEvent, loading } = useEvent();
  const [deleteType, setDeleteType] = useState("this");
  const deleteEventHandler = async () =>
    deleteEvent({ id: event.id, onSuccess, deleteType, onError });
  return (
    <SPModal visible={visible} onCancel={onCancel}>
      <SPText variant="h1" textAlign="center" marginBottom={24}>
        {event && event.isRecurring
          ? "Az eseményhez több időpont tartozik. Melyik alkalmat szeretnéd törölni?"
          : "Szeretnéd törölni az alábbi eseményt a rendszerből?"}
      </SPText>
      {event && event.isRecurring ? (
        <SPRadioButtons
          vertical
          value={deleteType}
          onChange={(value) => setDeleteType(value)}
          gap={24}
          options={[
            {
              value: "this",
              label: "Csak ezt az eseményt.",
            },
            {
              value: "fromThis",
              label: "Ezt, és az ezt követő összes időpontot.",
            },
          ]}
        />
      ) : (
        <SPText variant="selection-text" marginBottom={24}>
          Miután törölted az eseményt, értesítés megy a résztvevők részére az
          esemény elmaradásáról.
        </SPText>
      )}
      <SPButton
        disabled={loading}
        title="Törlés"
        onClick={deleteEventHandler}
      />
    </SPModal>
  );
};

const prepareFilterData = (data) => ({
  s: data.search || null,
});

function EventsPage() {
  const { deleteError, deleteSuccess } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState({
    search: location?.state?.search || "",
  });
  const { loading, events, meta, getEvents } = useEvents(
    prepareFilterData(filter),
    location?.state?.page || 0
  );
  const [eventToDelete, setEventToDelete] = useState(null);

  const { queryChanging } = useQuery({
    query: filter,
    timeout: 500,
    request: ({ nextQuery }) =>
      events &&
      getEvents({
        page: 0,
        filterData: prepareFilterData(nextQuery),
      }),
  });

  const Action = ({ item }) => (
    <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
      <SPButton
        shape="icon"
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 22 }}
        onClick={() => setEventToDelete(item)}
      />
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        onClick={() =>
          navigate(`/events/${item.id}`, {
            state: {
              search: filter.search,
              page: meta?.page,
            },
          })
        }
      />
    </div>
  );

  const columns = [
    {
      title: "Azonosító",
      field: "id",
    },
    {
      title: "Esemény neve",
      field: "name",
    },
    {
      title: "Esemény dátuma",
      field: "startDate",
      Component: ({ column }) => {
        const date = new Date(column).toLocaleDateString("hu-HU");
        return <SPText variant="selection-text">{date}</SPText>;
      },
    },
  ];

  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Események",
          highlighted: "listája",
        }}
      />
      <div className={styles["search-container"]}>
        <SPInput
          placeholder="Esemény neve..."
          PrimaryIcon={!queryChanging && SearchIcon}
          value={filter.search}
          onChange={(e) => setFilter({ ...filter, search: e.target.value })}
        />
      </div>
      {loading && <div>Loading...</div>}
      {events && (
        <SPTable
          items={events}
          meta={meta}
          onPageChange={(page) => getEvents({ page })}
          columns={columns}
          Action={Action}
        />
      )}
      <DeleteEventModal
        visible={eventToDelete}
        event={eventToDelete}
        onCancel={() => setEventToDelete(null)}
        onSuccess={async () => {
          deleteSuccess();
          setEventToDelete(null);
          await getEvents({ page: 0, filterData: prepareFilterData(filter) });
        }}
        onError={() => {
          deleteError();
        }}
      />
    </div>
  );
}

export default EventsPage;
export { DeleteEventModal };
