import React from "react";
import dayjs from "dayjs";
import SPText from "../../../SPText/SPText";
import styles from "./Details.module.css";

export default function Details({ event, marginBottom, width }) {
  const languages = {
    en: "angol",
    hu: "magyar",
  };
  const startDate = dayjs(event.startDateTime).format("YYYY.MM.DD.");
  const startTime = dayjs(event.startDateTime).format("HH:mm A");
  const endTime = dayjs(event.endDateTime).format("HH:mm A");
  return (
    <div style={{ width: width || "75%", marginBottom }}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <SPText variant="h3">Esemény dátuma</SPText>
            </th>
            <th>
              <SPText variant="h3">Sport</SPText>
            </th>
            <th>
              <SPText variant="h3">Esemény időpontja</SPText>
            </th>
            <th>
              <SPText variant="h3">Esemény nyelve</SPText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {startDate}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {event.sports.data
                  .map(
                    (sport) =>
                      sport.name.charAt(0).toUpperCase() + sport.name.slice(1)
                  )
                  .join(", ")}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {`${startTime} - ${endTime}`}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {event.languages
                  .map((language) => languages[language])
                  .join(", ")}
              </SPText>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
