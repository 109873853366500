import { useEffect, useState } from "react";
import logsApi from "../api/logs";

function useLogs(filter) {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState(null);
  const [meta, setMeta] = useState(null);

  const getLogs = async ({
    page = (meta && meta.page !== meta.pageTotal - 1
      ? meta.page
      : meta.page - 1) || 0,
    filterData,
  }) => {
    setLoading(true);
    console.log(filterData);
    const res = await logsApi.getLogs({ page, pageSize: 20, filterData });
    if (res.ok) {
      setLogs(res.data.data);
      setMeta(res.data.meta);
    } else {
      console.log("[useLogs, getLogs] error getting the logs");
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs({ page: 0, filterData: filter });
  }, []);

  return { loading, logs, meta, getLogs };
}

export default useLogs;
