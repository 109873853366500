import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./PostPage.module.css";
import { Header, SPText, SPModal, SPButton } from "../../components";
import { Head } from "../../components/pages/PostPage";
import usePost from "../../hooks/usePost";
import { ArrowSmallRight } from "../../components/icons";

function PostPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, post, deletePost } = usePost(params.postId);
  const [showModal, setShowModal] = useState(false);
  if (loading) {
    return (
      <div className={styles.container}>
        <Header
          title={{ plain: "Bejegyzés", highlighted: "megtekintése" }}
          withBack
        />
      </div>
    );
  }

  const onDeletePost = async () => {
    const res = await deletePost(post.id);
    setShowModal(false);
    if (res.ok) {
      navigate(`/posts`);
    }
  };

  return (
    <div className={styles.container}>
      <Header
        title={{ plain: "Bejegyzés", highlighted: "megtekintése" }}
        withBack
        backNavPath={"/posts"}
        backNavOptions={{ state: location?.state }}
      />
      <SPModal visible={showModal} onCancel={() => setShowModal(false)}>
        <SPText variant="h1" marginBottom={24} textAlign="center">
          Bejegyzés törlése
        </SPText>
        <SPText variant="selection-text" marginBottom={24}>
          Törlés után már nem fog megjelenni ez a bejegyzés a klubban.
        </SPText>
        <SPButton title="Törlés" onClick={onDeletePost} />
      </SPModal>
      <div className={styles.content}>
        <Head
          post={post}
          marginBottom={32}
          onClickDeleteButton={() => setShowModal(true)}
        />
        <SPText variant="h3" marginBottom={8}>
          Bejegyzés tartalma
        </SPText>
        <SPText variant="selection-text" marginBottom={32}>
          {post.text && <div dangerouslySetInnerHTML={{ __html: post.text }} />}
        </SPText>
        <SPText variant="h3" marginBottom={8}>
          Csatolt esemény
        </SPText>
        {post.event && (
          <div
            className={styles.event}
            onClick={() => navigate(`/events/${post.event?.id}`)}
          >
            <SPText variant="selection-text">{`${post.event?.name} `}</SPText>
            <ArrowSmallRight color="#FF8A4A" size={12} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PostPage;
