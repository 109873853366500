import * as React from "react";

function SplinkerLogoIcon({
  size = 507,
  color = "url(#paint0_linear_601_2359)",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 507 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.08 170.621c93.54.61 119.212 76.811 92.322 170.69C387.376 435.529 286.386 512 131.005 512l53.306-170.689c-93.54-.61-119.212-76.809-92.322-170.621C119.015 76.472 220.005 0 375.386 0L322.08 170.621zm0 0l-35.56 113.793h27.705l-17.749 56.897H184.311l53.306-170.69h84.463z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_601_2359"
          x1={160.468}
          y1={512}
          x2={313.384}
          y2={-0.00350632}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB2D14" />
          <stop offset={1} stopColor="#FF9048" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SplinkerLogoIcon;
