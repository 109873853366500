import PropTypes from "prop-types";
import SPText from "../SPText/SPText";
import styles from "./Badge.module.css";

export default function Badge({
  variant = "default",
  title,
  Component,
  color = "#333",
  textColor = "#FFFFFF",
  marginTop = 0,
  marginBottom = 0,
  outlined = false,
  ...props
}) {
  return (
    <div className={styles[variant]} style={{
      border: outlined ? `1px solid ${color}` : 'none',
      background: !outlined ? color : 'none',
      marginBottom,
      marginTop
    }} {...props}>
      {title && (
        <SPText variant="button-name" color={textColor}>
          {title}
        </SPText>
      )}
      {Component && Component}
    </div>
  );
}

Badge.propTypes = {
  title: PropTypes.any,
  variant: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  Component: PropTypes.element,
};
