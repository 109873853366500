import client, { requestConfig } from "./client";

const endpoint = "/events";

const getEvents = ({ page = 0, pageSize = 20, filterData }) =>
  client.get(
    endpoint,
    {
      as: "admin",
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );
const getEvent = ({ id }) =>
  client.get(
    `${endpoint}/${id}`,
    { as: "admin", view: "extended" },
    { headers: { ...requestConfig().headers } }
  );

const deleteEvent = ({ id, deleteType }) =>
  client.delete(
    `${endpoint}/${id}`,
    {},
    {
      data: {deleteType},
      headers: {
        ...requestConfig().headers,
      },
    }
  );

const patchEvent = ({ id, data }) =>
  client.patch(
    `${endpoint}/${id}`,
    { ...data },
    { headers: { ...requestConfig().headers } }
  );

export default {
  getEvents,
  getEvent,
  deleteEvent,
  patchEvent,
};
