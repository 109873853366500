import * as React from "react";

function CrossIcon({ size = 14, color = "#333", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.585.415a.562.562 0 00-.795 0L7 6.205 1.21.415a.563.563 0 00-.795.795L6.205 7l-5.79 5.79a.563.563 0 00.795.796L7 7.795l5.79 5.79a.562.562 0 00.796-.796L7.795 7l5.79-5.79a.562.562 0 000-.795z"
        fill={color}
      />
    </svg>
  );
}

export default CrossIcon;
