import React from "react";
import styles from "./ImageFormCard.module.css";
import { CloseIcon, ImageFilePlaceholderIcon } from "../icons";
import Card from "../Card/Card";
import SPButton from "../SPButton/SPButton";
import colors from "../../config/colors";

export default function ImageFormCard({
  imageUrl,
  removeBtn = true,
  onRemove = () => {},
}) {
  if (!imageUrl) {
    return (
      <Card className={styles.card}>
        <ImageFilePlaceholderIcon />
      </Card>
    );
  }

  const background = `url(${imageUrl})`;

  return (
    <Card
      className={`${styles.card}`}
      style={{ backgroundImage: background, backgroundSize: "cover" }}
    >
      {removeBtn && (
        <SPButton
          variant="secondary"
          Icon={CloseIcon}
          shape="icon"
          className={styles.removeButton}
          IconProps={{ color: colors.light.marking.red, size: 11 }}
          onClick={() => onRemove()}
        />
      )}
    </Card>
  );
}
