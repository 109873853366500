import * as React from "react";

function CheckMarkIcon({ size = 13, color = "white", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 8.075L5.31579 11L11 2"
        stroke={color}
        strokeWidth={2.19692}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckMarkIcon;
