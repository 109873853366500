import { useEffect } from "react";

const useApi = ({
  call,
  initParams,
  onInitSuccess = null,
  onInitError = null,
  loading,
  data,
  init = null,
}) => {
  const setLoading = loading[1];
  const setData = data ? data[1] : null;

  const request = async ({ onSuccess, onError, ...params }) => {
    setLoading(true);
    const res = await call({ ...initParams, ...params });
    if (res.ok) {
      setData && setData(res.data);
      onSuccess && onSuccess(res.data);
    } else {
      console.log(JSON.stringify(res.data));
      onError && onError(res.data);
    }
    setLoading(false);
    return res.data;
  };

  useEffect(() => {
    if (init) {
      const initRequest = async () => {
        const setInitLoading = init[1];
        setInitLoading(true);
        await request({
          onSuccess: onInitSuccess,
          onError: onInitError,
          ...initParams,
        });
        setInitLoading(false);
      };
      initRequest();
    }
  }, []);

  return {
    request,
  };
};

export default useApi;
