import React, { createRef, forwardRef, useEffect, useState } from "react";
import SPText from "../SPText/SPText";
import styles from "./SPInput.module.css";
import colors from "../../config/colors";
import SPInputError from "./SPInputError";

/**
 * ### SPInput
 * **Splinker** input component.
 * @component
 * @prop {number} marginBottom Spacing in px below the input.
 * @prop {React.ReactElement} PrimaryIcon PrimaryIcon which shows on the right of the input.
 * @prop {React.ReactElement} SecondaryIcon SecondaryIcon which shows on the left of the PrimaryIcon.
 * @prop {function} onClickPrimaryIcon function which will be executed after the click on the PrimaryIcon.
 * @prop {function} onClickSecondaryIcon function which will be executed after the click on the SecondaryIcon.
 * @prop {object} primaryIconProps props that will be passed to the PrimaryIcon.
 * @prop {object} secondaryIconProps props that will be passed to the SecondaryIcon.
 * @prop {number} width the width of the SPInput component
 * @prop {number} label label of the input
 * @prop {string} error validation error
 * @returns {React.ReactElement} SPInput
 * @example
 * return (
 *  <SPInput
 *  />
 * )
 */
const SPInput = (
  {
    marginBottom,
    PrimaryIcon = null,
    SecondaryIcon = null,
    onClickPrimaryIcon,
    onClickSecondaryIcon,
    primaryIconProps,
    secondaryIconProps,
    width,
    label,
    onBlur,
    type = "",
    error,
    onFocusChanged,
    accept = [],
    ...props
  },
  ref
) => {
  const inputRef = createRef(ref);
  const [focused, setFocused] = useState(ref?.current?.onfocus);

  useEffect(() => {
    onFocusChanged && onFocusChanged(focused);
  }, [focused]);

  return (
    <div style={{ width: width || "330px" }}>
      {label && (
        <SPText marginBottom={8} variant="h3">
          {label}
        </SPText>
      )}
      <div
        className={`${styles.container} ${focused && styles.focus}`}
        style={{ width, marginBottom, position: "relative" }}
        onMouseDown={(e) => {
          e.preventDefault();
          inputRef?.current?.focus();
        }}
      >
        {type === "file" && (
          <>
            <input
              className={styles.input}
              style={{
                position: "absolute",
                left: "0px",
                top: "0px",
                opacity: 0,
                width: "100%",
                height: "100%",
              }}
              // wrapper onMouseDown event should not affect the input onMouseDown event
              onMouseDown={(e) => e.stopPropagation()}
              onBlur={(e) => {
                onBlur && onBlur(e);
                setFocused(false);
              }}
              onFocus={() => setFocused(true)}
              ref={inputRef}
              type={type}
              accept={accept.join(', ')}
              {...props}
            />
            <SPText color={colors.light.gray.gray1} variant="comment-text">
              Feltöltés
            </SPText>
          </>
        )}
        {type !== "file" && (
          <input
            className={styles.input}
            // wrapper onMouseDown event should not affect the input onMouseDown event
            onMouseDown={(e) => e.stopPropagation()}
            onBlur={(e) => {
              onBlur && onBlur(e);
              setFocused(false);
            }}
            onFocus={() => setFocused(true)}
            ref={inputRef}
            type={type}
            {...props}
          />
        )}

        <div className={styles.icons}>
          {SecondaryIcon && (
            <div
              className={styles.icon}
              style={{ cursor: onClickSecondaryIcon && "pointer" }}
              onClick={onClickSecondaryIcon}
            >
              <SecondaryIcon {...secondaryIconProps} />
            </div>
          )}
          {PrimaryIcon && (
            <div
              className={styles.icon}
              style={{ cursor: onClickPrimaryIcon && "pointer" }}
              onClick={onClickPrimaryIcon}
            >
              <PrimaryIcon {...primaryIconProps} />
            </div>
          )}
        </div>
      </div>
      <SPInputError error={error} />
    </div>
  );
};

export default forwardRef(SPInput);
