export default function CalendarIcon({
  size = 24,
  color = "url(#paint0_linear_1540_3983)",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1594_3647)">
        <path
          d="M19 2.5H18V1.5C18 1.23478 17.8946 0.98043 17.7071 0.792893C17.5196 0.605357 17.2652 0.5 17 0.5C16.7348 0.5 16.4804 0.605357 16.2929 0.792893C16.1054 0.98043 16 1.23478 16 1.5V2.5H8V1.5C8 1.23478 7.89464 0.98043 7.70711 0.792893C7.51957 0.605357 7.26522 0.5 7 0.5C6.73478 0.5 6.48043 0.605357 6.29289 0.792893C6.10536 0.98043 6 1.23478 6 1.5V2.5H5C3.67441 2.50159 2.40356 3.02888 1.46622 3.96622C0.528882 4.90356 0.00158786 6.17441 0 7.5L0 19.5C0.00158786 20.8256 0.528882 22.0964 1.46622 23.0338C2.40356 23.9711 3.67441 24.4984 5 24.5H19C20.3256 24.4984 21.5964 23.9711 22.5338 23.0338C23.4711 22.0964 23.9984 20.8256 24 19.5V7.5C23.9984 6.17441 23.4711 4.90356 22.5338 3.96622C21.5964 3.02888 20.3256 2.50159 19 2.5ZM2 7.5C2 6.70435 2.31607 5.94129 2.87868 5.37868C3.44129 4.81607 4.20435 4.5 5 4.5H19C19.7956 4.5 20.5587 4.81607 21.1213 5.37868C21.6839 5.94129 22 6.70435 22 7.5V8.5H2V7.5ZM19 22.5H5C4.20435 22.5 3.44129 22.1839 2.87868 21.6213C2.31607 21.0587 2 20.2956 2 19.5V10.5H22V19.5C22 20.2956 21.6839 21.0587 21.1213 21.6213C20.5587 22.1839 19.7956 22.5 19 22.5Z"
          fill={color}
        />
        <path
          d="M12 17C12.8284 17 13.5 16.3284 13.5 15.5C13.5 14.6716 12.8284 14 12 14C11.1716 14 10.5 14.6716 10.5 15.5C10.5 16.3284 11.1716 17 12 17Z"
          fill={color}
        />
        <path
          d="M7 17C7.82843 17 8.5 16.3284 8.5 15.5C8.5 14.6716 7.82843 14 7 14C6.17157 14 5.5 14.6716 5.5 15.5C5.5 16.3284 6.17157 17 7 17Z"
          fill={color}
        />
        <path
          d="M17 17C17.8284 17 18.5 16.3284 18.5 15.5C18.5 14.6716 17.8284 14 17 14C16.1716 14 15.5 14.6716 15.5 15.5C15.5 16.3284 16.1716 17 17 17Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1594_3647">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
        <linearGradient
          id="paint0_linear_1540_3983"
          x1="-0.0320435"
          y1="11.9776"
          x2="23.9877"
          y2="11.9776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB3318" />
          <stop offset="1" stopColor="#FF8A4A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
