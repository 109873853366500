import { useEffect, useState } from "react";
import sportsApi from "../api/sports";

function useSport(id) {
  const [loading, setLoading] = useState(true);
  const [sport, setSport] = useState(null);

  const upsertSport = async ({
    sportId,
    name,
    icon,
    covers,
    status = "approved",
    cb,
  }) => {
    setLoading(true);
    let iconForm = null;
    let coversForm = null;

    if (icon) {
      iconForm = new FormData();
      iconForm.append("image", icon);
    }

    if (covers && covers.length > 0) {
      coversForm = new FormData();
      covers.forEach((cover, index) => {
        coversForm.append(`image${index}`, cover);
      });
    }

    if (sportId) {
      if (sportId === "new") {
        if (!icon || covers?.length === 0) {
          setLoading(false);
          return;
        }
        const res = await sportsApi.create({ name });
        if (res.ok) {
          const iconRes = await sportsApi.uploadIcon(res.data.id, iconForm);
          const coversRes = await sportsApi.uploadCovers(
            res.data.id,
            coversForm
          );
          if (!iconRes) console.log(iconRes.data);
          if (!coversRes) console.log(iconRes.data);
          console.log("successfully created sport");
          cb && cb(res);
        }
      } else {
        const res = await sportsApi.update({ id: sportId, name, status });
        if (res.ok) {
          if (icon) {
            const iconRes = await sportsApi.uploadIcon(res.data.id, iconForm);
            if (!iconRes) console.log(iconRes.data);
          }

          if (covers && covers.length > 0) {
            const coversRes = await sportsApi.uploadCovers(
              res.data.id,
              coversForm
            );
            if (!coversRes) console.log(coversRes.data);
          }
          console.log("successfully updated sport");
          cb && cb(res);
        }
      }
    }

    setLoading(false);
  };

  const getSport = async () => {
    setLoading(true);
    const res = await sportsApi.getSport(id);
    if (res.ok) {
      setSport(res.data);
    }
    setLoading(false);
  };

  const deleteSport = async (sportId) => {
    setLoading(true);
    const res = await sportsApi.deleteSport(sportId);
    setLoading(false);
    return res;
  };

  const deleteSportCover = async (sportId, coverId) => {
    setLoading(true);
    const res = await sportsApi.deleteSportCover(sportId, coverId);
    setLoading(false);
    return res;
  };

  useEffect(() => {
    const initSport = async () => {
      if (id && id !== "new") {
        await getSport();
      }
      setLoading(false);
    };
    initSport();
  }, []);

  return {
    loading,
    sport,
    deleteSport,
    getSport,
    upsertSport,
    deleteSportCover,
  };
}

export default useSport;
