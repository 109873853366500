import React from "react";
import PropTypes from "prop-types";
import SPText from "../../../SPText/SPText";
import styles from "./Footer.module.css";

export default function Footer({ event, marginBottom }) {
  return (
    <div className={styles.container} styles={{ marginBottom }}>
      <div className={styles.cell}>
        <SPText variant="h3">Létrehozás dátuma</SPText>
        <SPText variant="selection-text" color="#ABABAB">
          {event.createdAt &&
            new Date(event.createdAt).toLocaleDateString("hu-HU")}
        </SPText>
      </div>
      <div className={styles.cell}>
        <SPText variant="h3">Módosítás dátuma</SPText>
        <SPText variant="selection-text" color="#ABABAB">
          {event.updatedAt &&
            new Date(event.updatedAt).toLocaleDateString("hu-HU")}
        </SPText>
      </div>
    </div>
  );
}

Footer.propTypes = {
  registration: PropTypes.object,
  suspension: PropTypes.object,
  marginBottom: PropTypes.number,
};
