import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  HouseIcon,
  CalendarIcon,
  FormIcon,
  // ShieldIcon,
  SplinkerLogoIcon,
  CommentIcon,
  RunningIcon,
  PeopleIcon,
  ClubIcon,
} from "../icons";
import SPText from "../SPText/SPText";
import SPModal from "../SPModal/SPModal";
import SPButton from "../SPButton/SPButton";
import styles from "./Navbar.module.css";

function Navbar() {
  const { signOut } = useAuth();

  const LINKS = [
    { to: "/dashboard", label: "Vezérlőpult", Icon: HouseIcon },
    { to: "/users", label: "Felhasználók", Icon: PeopleIcon },
    { to: "/posts", label: "Bejegyzések", Icon: CommentIcon },
    { to: "/sports", label: "Sportok", Icon: RunningIcon },
    { to: "/events", label: "Események", Icon: CalendarIcon },
    { to: "/clubs", label: "Klubbok", Icon: ClubIcon },
    { to: "/logs", label: "Logok", Icon: FormIcon },
    // { to: "/docs", label: "Jogi dokumentumok", Icon: ShieldIcon },
  ];

  const handleSignOut = async () => {
    await signOut();
  };

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  return (
    <div className={`${styles.container} hide-scrollbar`}>
      {/* Navigation links */}
      <div className={styles["top-container"]}>
        <div className={styles["logo-container"]}>
          <SplinkerLogoIcon size={40} />
          <SPText variant="event-club" color="#FFFFFF">
            Splinker
          </SPText>
        </div>
        <div className={styles["link-container"]}>
          {LINKS.map(({ to, Icon, label }) => (
            <NavLink
              key={to}
              className={({ isActive }) =>
                `${styles.link} ${isActive ? styles["active-link"] : ""}`
              }
              to={to}
              children={({ isActive }) => (
                <div className={styles["link-content"]}>
                  {Icon && (
                    <Icon size={24} color={isActive ? "#FF8A4A" : "#FFFFFF"} />
                  )}
                  <SPText
                    variant="button-name"
                    color={isActive ? "#FF8A4A" : "#FFFFFF"}
                  >
                    {label}
                  </SPText>
                </div>
              )}
            />
          ))}
          {/* Sign out link */}
        </div>
      </div>
      <div className={styles["bottom-container"]}>
        <div className={styles["link-container"]}>
          <NavLink onClick={() => setLogoutModalVisible(true)}>
            <div className={styles["link-content"]}>
              <CalendarIcon size={24} color="#CCCCCC" />
              <SPText variant="button-name" color="#FFFFFF">
                Kijelentkezés
              </SPText>
            </div>
          </NavLink>
        </div>
        <SPModal
          visible={logoutModalVisible}
          onCancel={() => setLogoutModalVisible(false)}
        >
          <SPText variant="h1" textAlign="center">
            Kijelentkezés
          </SPText>
          <SPText variant="selection-text" textAlign="center">
            Kijelentkezel a Splinker admin felületéből?
          </SPText>
          <SPButton title="Kijelentkezés" onClick={handleSignOut} />
        </SPModal>
      </div>
    </div>
  );
}

export default Navbar;
