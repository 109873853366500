import React from "react";
import PropTypes from "prop-types";
import SPText from "../../../SPText/SPText";
import styles from "./Description.module.css";

export default function Description({ event, marginBottom }) {
  return (
    <div className={styles.container} style={{ marginBottom }}>
      <SPText variant="h3">Esemény leírása</SPText>
      <SPText variant="selection-text" color="#ababab">
        {event?.description || "-"}
      </SPText>
    </div>
  );
}

Description.propTypes = {
  user: PropTypes.object,
  marginBottom: PropTypes.number,
};
