import { useState } from "react";
import eventsApi from "../api/events";
import useApi from "./useApi";

function useEvent(id) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const { request: getEvent } = useApi({
    call: eventsApi.getEvent,
    loading: [loading, setLoading],
    init: id ? [initLoading, setInitLoading] : null,
    data: [event, setEvent],
    initParams: id ? { id } : null,
  });

  const deleteEvent = async ({
    id: eventId,
    onSuccess,
    deleteType,
    onError,
  }) => {
    setLoading(true);
    const res = await eventsApi.deleteEvent({ id: eventId, deleteType });
    setLoading(false);
    if (res.ok) {
      onSuccess && onSuccess();
    } else {
      onError && onError();
    }
  };

  const { request: patchEvent } = useApi({
    call: eventsApi.patchEvent,
    loading: [loading, setLoading],
    data: [event, setEvent],
  });

  return {
    initLoading,
    loading,
    event,

    getEvent,
    deleteEvent,
    patchEvent,
  };
}

export default useEvent;
