import React, { createRef, forwardRef } from "react";
import PropTypes from "prop-types";
import SPText from "../SPText/SPText";
import styles from "./SPRadioButtons.module.css";

function SPRadioButtons({
  name,
  value,
  onChange,
  options,
  vertical = false,
  gap,
  ...props
}, ref) {
  const inputRef = ref || createRef(null);
  return (
    <div
      className={`${styles.buttons} ${vertical && styles.flexColumn}`}
      style={{ gap: `${gap}px` }}
    >
      {options.map((option) => (
        <label key={option.value} className={styles.container}>
          <input
            type="radio"
            name={name}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            ref={inputRef}
            {...props}
          />
          <div className={styles.radiomark}></div>
          <SPText>{option.label}</SPText>
        </label>
      ))}
    </div>
  );
}

export default forwardRef(SPRadioButtons);

SPRadioButtons.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  gap: PropTypes.number,
  vertical: PropTypes.bool, // we might need vertical orientation
};
