export default function ArrowSmallDown({ size = 24, color = "url(#paint0_linear_1540_3983)", ...props }) {
    return (
        <svg width={size} height={size} viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.1509 0.525055C13.0657 0.439137 12.9643 0.370942 12.8526 0.324404C12.7409 0.277866 12.621 0.253906 12.5 0.253906C12.379 0.253906 12.2592 0.277866 12.1475 0.324404C12.0358 0.370942 11.9344 0.439137 11.8492 0.525055L7.65087 4.72339C7.56565 4.80931 7.46427 4.8775 7.35256 4.92404C7.24086 4.97058 7.12105 4.99454 7.00003 4.99454C6.87902 4.99454 6.75921 4.97058 6.64751 4.92404C6.5358 4.8775 6.43442 4.80931 6.3492 4.72339L2.15087 0.525055C2.06565 0.439137 1.96427 0.370942 1.85256 0.324404C1.74086 0.277866 1.62105 0.253906 1.50003 0.253906C1.37902 0.253906 1.25921 0.277866 1.14751 0.324404C1.0358 0.370942 0.934417 0.439137 0.849201 0.525055C0.678471 0.696804 0.582642 0.929134 0.582642 1.1713C0.582642 1.41347 0.678471 1.64581 0.849201 1.81755L5.0567 6.02505C5.57233 6.54004 6.27128 6.8293 7.00003 6.8293C7.72879 6.8293 8.42774 6.54004 8.94337 6.02505L13.1509 1.81755C13.3216 1.64581 13.4174 1.41347 13.4174 1.1713C13.4174 0.929134 13.3216 0.696804 13.1509 0.525055Z" fill={color}/>
            <defs>
                <linearGradient
                    id="paint0_linear_1540_3983"
                    x1="-0.0320435"
                    y1="11.9776"
                    x2="23.9877"
                    y2="11.9776"
                    gradientUnits="userSpaceOnUse"
                    >
                    <stop stopColor="#EB3318" />
                    <stop offset="1" stopColor="#FF8A4A" />
                </linearGradient>
            </defs>
        </svg>
    );
}