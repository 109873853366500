import React from "react";
import dayjs from 'dayjs';
import SPText from "../../../SPText/SPText";
import SPButton from "../../../SPButton/SPButton";
import { TrashIcon } from "../../../icons";
import styles from "./Head.module.css";

export default function Head({
  post,
  marginBottom,
  style = {},
  onClickDeleteButton,
  ...props
}) {
    const createdAt = dayjs(post.createdAt).format('YYYY.MM.DD hh:mm A') || '-';
    const modifiedAt = dayjs(post.modifiedAt).format('YYYY.MM.DD hh:mm A') || '-';
  return (
    <div
      className={styles.container}
      style={{ ...style, marginBottom }}
      {...props}
    >
      <div className={styles.left}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <SPText variant="h3">Bejegyzés ID</SPText>
            </th>
            <th>
              <SPText variant="h3">Klub ID</SPText>
            </th>
            <th>
              <SPText variant="h3">Létrehozás dátuma</SPText>
            </th>
            <th>
              <SPText variant="h3">Módosítás dátuma</SPText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {post?.id}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {post?.club?.id}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {createdAt}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {modifiedAt}
              </SPText>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <SPButton
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 28 }}
        title="Bejegyzés törlése"
        onClick={onClickDeleteButton}
      />
    </div>
  );
}
