import React from "react";
import dayjs from "dayjs";
import SPText from "../../../SPText/SPText";
import styles from "./Details.module.css";

export default function Details({ club, marginBottom, width }) {
  console.log(club);
  const created = club?.createdAt
    ? dayjs(club.createdAt).format("YYYY.MM.DD.")
    : "-";
  const modified = club?.updatedAt
    ? dayjs(club.updatedAt).format("YYYY.MM.DD.")
    : "-";
  return (
    <div style={{ width: width || "75%", marginBottom }}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <SPText variant="h3">Admin ID</SPText>
            </th>
            <th>
              <SPText variant="h3">Sport</SPText>
            </th>
            <th>
              <SPText variant="h3">Létrehozás dátuma</SPText>
            </th>
            <th>
              <SPText variant="h3">Módosítás dátuma</SPText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {club?.admin?.id}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {club?.sports?.data
                  .map(
                    (sport) =>
                      sport.name.charAt(0).toUpperCase() + sport.name.slice(1)
                  )
                  .join(", ")}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {created}
              </SPText>
            </td>
            <td>
              <SPText variant="selection-text" color="#ABABAB">
                {modified}
              </SPText>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
