import client, { requestConfig } from "./client";

const endpoint = "/users";

const getUsers = ({ page = 0, pageSize = 20, filterData }) =>
  client.get(
    endpoint,
    {
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );

const getUser = ({ id }) =>
  client.get(
    `${endpoint}/${id}`,
    { as: "admin" },
    { headers: { ...requestConfig().headers } }
  );

const deleteUser = ({ id }) =>
  client.delete(
    `${endpoint}/${id}`,
    { as: "admin" },
    { headers: { ...requestConfig().headers } }
  );

export default {
  getUsers,
  getUser,
  deleteUser,
};
