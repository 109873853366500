import { useState } from "react";
import clubsApi from "../api/clubs";
import useApi from "./useApi";

function useClub(id) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [club, setClub] = useState(null);

  const upsertClub = async ({
    clubId,
    name,
    type,
    maxMembers,
    description,
    admin,
    sports,
    cover,
    cb,
  }) => {
    setLoading(true);
    let coverForm = null;
    if (cover) {
      coverForm = new FormData();
      coverForm.append("image", cover);
    }
    if (clubId) {
      if (clubId === "new") {
        const res = await clubsApi.create({
          name,
          type,
          maxMembers,
          description,
          admin,
          sports,
        });

        if (coverForm) {
          await clubsApi.uploadCover({ id: res.data.id, data: coverForm });
        }
        if (res.ok) {
          console.log("successfully created club");
          cb && cb(res);
        }
      } else {
        const res = await clubsApi.update({
          id: clubId,
          name,
          type,
          maxMembers,
          description,
          admin,
          sports,
        });
        if (cover) {
          console.log(coverForm);
          await clubsApi.uploadCover({ id: clubId, data: coverForm });
        }
        if (res.ok) {
          console.log("successfully updated club");
          cb && cb(res);
        }
      }
    }

    setLoading(false);
  };

  const { request: getClub } = useApi({
    call: clubsApi.getClub,
    loading: [loading, setLoading],
    init: id ? [initLoading, setInitLoading] : null,
    data: [club, setClub],
    initParams: id && id !== "new" ? { id } : null,
  });

  const { request: deleteClub } = useApi({
    call: clubsApi.deleteClub,
    loading: [loading, setLoading],
  });

  return {
    loading,
    club,
    upsertClub,
    deleteClub,
    getClub,
  };
}

export default useClub;
