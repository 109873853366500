export default function PlusIcon({
  size = 24,
  color = "url(#paint0_linear_1540_78)",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5625 8.3125H9.6875V1.4375C9.6875 1.25516 9.61507 1.0803 9.48614 0.951364C9.3572 0.822433 9.18234 0.75 9 0.75V0.75C8.81766 0.75 8.6428 0.822433 8.51386 0.951364C8.38493 1.0803 8.3125 1.25516 8.3125 1.4375V8.3125H1.4375C1.25516 8.3125 1.0803 8.38493 0.951364 8.51386C0.822433 8.6428 0.75 8.81766 0.75 9H0.75C0.75 9.18234 0.822433 9.3572 0.951364 9.48614C1.0803 9.61507 1.25516 9.6875 1.4375 9.6875H8.3125V16.5625C8.3125 16.7448 8.38493 16.9197 8.51386 17.0486C8.6428 17.1776 8.81766 17.25 9 17.25C9.18234 17.25 9.3572 17.1776 9.48614 17.0486C9.61507 16.9197 9.6875 16.7448 9.6875 16.5625V9.6875H16.5625C16.7448 9.6875 16.9197 9.61507 17.0486 9.48614C17.1776 9.3572 17.25 9.18234 17.25 9C17.25 8.81766 17.1776 8.6428 17.0486 8.51386C16.9197 8.38493 16.7448 8.3125 16.5625 8.3125Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1540_78"
          x1="0.75"
          y1="9"
          x2="17.25"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB3318" />
          <stop offset="1" stopColor="#FF8A4A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
