import * as React from "react";

function PictureIcon({ size = 24, color = "#FF8A4A", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2039_2570)">
        <path
          d="M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM5 2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V19C21.9983 19.4455 21.8957 19.8848 21.7 20.285L12.537 11.122C12.0727 10.6576 11.5214 10.2892 10.9147 10.0378C10.308 9.78644 9.65772 9.65707 9.001 9.65707C8.34428 9.65707 7.69399 9.78644 7.08728 10.0378C6.48056 10.2892 5.92931 10.6576 5.465 11.122L2 14.586V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2ZM5 22C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V17.414L6.878 12.536C7.1566 12.2572 7.4874 12.0361 7.85151 11.8852C8.21561 11.7343 8.60587 11.6566 9 11.6566C9.39413 11.6566 9.78439 11.7343 10.1485 11.8852C10.5126 12.0361 10.8434 12.2572 11.122 12.536L20.285 21.7C19.8848 21.8957 19.4455 21.9983 19 22H5Z"
          fill={color}
        />
        <path
          d="M16 10.5C16.6922 10.5 17.3689 10.2947 17.9445 9.91014C18.5201 9.52556 18.9687 8.97893 19.2336 8.33939C19.4985 7.69985 19.5678 6.99612 19.4327 6.31719C19.2977 5.63825 18.9644 5.01461 18.4749 4.52513C17.9854 4.03564 17.3617 3.7023 16.6828 3.56725C16.0039 3.4322 15.3001 3.50152 14.6606 3.76642C14.0211 4.03133 13.4744 4.47993 13.0899 5.05551C12.7053 5.63108 12.5 6.30777 12.5 7C12.5 7.92826 12.8687 8.8185 13.5251 9.47487C14.1815 10.1313 15.0717 10.5 16 10.5ZM16 5.5C16.2967 5.5 16.5867 5.58798 16.8334 5.7528C17.08 5.91762 17.2723 6.15189 17.3858 6.42598C17.4993 6.70007 17.5291 7.00167 17.4712 7.29264C17.4133 7.58361 17.2704 7.85088 17.0607 8.06066C16.8509 8.27044 16.5836 8.4133 16.2926 8.47118C16.0017 8.52906 15.7001 8.49935 15.426 8.38582C15.1519 8.27229 14.9176 8.08003 14.7528 7.83336C14.588 7.58668 14.5 7.29667 14.5 7C14.5 6.60218 14.658 6.22065 14.9393 5.93934C15.2206 5.65804 15.6022 5.5 16 5.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2039_2570">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PictureIcon;
