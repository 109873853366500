import { useState } from "react";
import { toast } from "react-toastify";
import postsApi from "../api/posts";
import useApi from "./useApi";

function usePosts(filter, defaultPage = 0) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { request: getPosts } = useApi({
    call: postsApi.getPosts,
    loading: [loading, setLoading],
    init: [initLoading, setInitLoading],
    initParams: {
      page: defaultPage,
      filterData: filter,
    },
    data: [data, setData],
  });

  const deletePost = async (postId) => {
    setLoading(true);
    const res = await postsApi.deletePost(postId);
    if (res.ok) {
      toast.success("Törlés sikeres");
    } else {
      toast.error("Hiba történt, próbáld újra");
    }
    setLoading(false);
  };

  return {
    initLoading,
    loading,
    posts: data?.data,
    meta: data?.meta,
    getPosts,
    deletePost,
  };
}

export default usePosts;
