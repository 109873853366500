export default function UserIcon({ size = 24, color = "#333", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 12C12.8137 12 15.5 9.31371 15.5 6C15.5 2.68629 12.8137 0 9.5 0C6.18629 0 3.5 2.68629 3.5 6C3.5 9.31371 6.18629 12 9.5 12Z"
        fill={color}
      />
      <path
        d="M9.5 14C4.53172 14.0055 0.505531 18.0317 0.5 23C0.5 23.5523 0.947703 24 1.49998 24H17.5C18.0522 24 18.5 23.5523 18.5 23C18.4945 18.0317 14.4683 14.0055 9.5 14Z"
        fill={color}
      />
    </svg>
  );
}
