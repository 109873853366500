import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card/Card";
import Badge from "../../../Badge/Badge";
import { BellIcon } from "../../../icons";
import SPText from "../../../SPText/SPText";

export default function SportRequestsCard({ items, Action }) {
  const Header = () => (
    <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
      <BellIcon size={24} />
      <SPText variant="event-club">Sport kérelmek</SPText>
      <Badge title={items ? items.length.toString() : '0'} />
    </div>
  );
  const SportRequest = ({ item }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "75px",
        borderBottom: "1px solid #CCCCCC",
      }}
    >
      <SPText variant="h3">{item.name.hu}</SPText>
      <Action item={item} />
    </div>
  );
  return (
    <Card Header={Header}>
      {items && items.map((request) => (
        <SportRequest key={request.id} item={request} />
      ))}
    </Card>
  );
}

SportRequestsCard.propTypes = {
  sportRequests: PropTypes.arrayOf(PropTypes.object),
};
