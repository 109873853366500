import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SPButton, SPInput, SPText } from "../../components";
import useAuth from "../../hooks/useAuth";
import { EyeIcon, FireIcon } from "../../components/icons";
import styles from "./LoginPage.module.css";

function LoginPage() {
  const [passwordType, setPasswordType] = useState("password");
  const { login } = useAuth();
  const [errors, setErrors] = useState({});
  const { control, handleSubmit } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const emailRef = useRef();

  const passwordRef = useRef();

  const handleLogin = async (data) => {
    setErrors({});
    const resp = await login({ ...data });
    if (!resp.ok && resp.data.errors) {
      console.log(resp.data.errors.body);
      const tmpErrors = {};
      Object.keys(resp.data.errors.body).forEach((field) => {
        const [message] = resp.data.errors.body[field].messages;
        tmpErrors[field] = message;
      });
      setErrors(tmpErrors);
    }
  };

  const togglePassword = () => {
    setPasswordType(passwordType === "text" ? "password" : "text");
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(handleLogin)}>
        <FireIcon size={45} marginBottom={16} />
        <SPText variant="h1">SPLINKER Admin</SPText>
        <SPText variant="h1" color="#EB3318" marginBottom={16}>
          Bejelentkezés
        </SPText>
        <SPText variant="selection-text" textAlign="center" marginBottom={32}>
          Integer sit mauris diam pulvinar. Nulla sed suspendisse et, quisque
          lorem ut.
        </SPText>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <SPInput
              {...field}
              placeholder="E-mail cím"
              width="100%"
              ref={emailRef}
              error={errors.email}
            />
          )}
        />

        <SPText
          marginBottom={16}
          variant="comment-text"
          color="#EC6878"
        ></SPText>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <SPInput
              {...field}
              type={passwordType}
              placeholder="Jelszó"
              width="100%"
              SecondaryIcon={EyeIcon}
              onClickSecondaryIcon={togglePassword}
              marginBottom={32}
              ref={passwordRef}
              error={errors.password}
            />
          )}
        />

        <SPText variant="comment-text" color="#EC6878"></SPText>
        <SPButton title="Bejelentkezés" type="submit" />
      </form>
    </div>
  );
}

export default LoginPage;
