import { useContext } from "react";
import { AuthContext } from "../context/auth";

function useAuth() {
  const { auth, login, signOut } = useContext(AuthContext);

  return { auth, login, signOut };
}

export default useAuth;
