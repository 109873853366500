import { useReducer, createContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import authApi from "../api/auth";
import useStorage from "../hooks/useStorage";

const AuthContextType = {
  login: PropTypes.func,
  singOut: PropTypes.func,
  auth: PropTypes.shape({
    loading: PropTypes.bool,
    initLoading: PropTypes.bool,
    token: PropTypes.string,
  }),
};

// function handling state changes
const reducer = (state, action) => {
  // select the action type
  // action.type = 'UPPERCASE'
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.loading };
    case "INIT_LOADING":
      return { ...state, initLoading: action.loading };
    case "LOGIN":
      return { ...state, token: action.token };
    case "SIGN_OUT":
      return { ...state, token: action.token };
    default:
      return { ...state };
  }
};

const useAuthContext = () => {
  const [auth, dispatch] = useReducer(reducer, {
    token: null,
    initLoading: true,
  });
  const { get: getStorageItem, set: setStorageItem } = useStorage();

  const login = async ({ email, password }) => {
    dispatch({ type: "LOADING", loading: true });
    const loginRes = await authApi.login({ email, password });
    if (loginRes.ok) {
      setStorageItem("jwt", loginRes.data.token);
      dispatch({ token: loginRes.data.token, type: "LOGIN" });
    } else {
      console.log(
        "[authContext, login] error logging in:",
        loginRes.data.errors
      );
    }
    dispatch({ type: "LOADING", loading: false });
    return loginRes;
  };

  const signOut = async () => {
    dispatch({ type: "LOADING", loading: true });
    setStorageItem("jwt", null);
    dispatch({ token: null, type: "SIGN_OUT" });
    dispatch({ type: "LOADING", loading: false });
  };

  const authContext = useMemo(() => ({
    // sign-in handler
    login,
    // sign-out handler
    signOut,
  }));

  const initAuth = async () => {
    dispatch({ loading: true, type: "INIT_LOADING" });
    const token = getStorageItem("jwt");
    if (token) {
      dispatch({ token, type: "LOGIN" });
    }
    dispatch({ loading: false, type: "INIT_LOADING" });
  };

  useEffect(() => {
    initAuth();
  }, []);

  return {
    auth,
    authContext,
  };
};

const AuthContext = createContext(AuthContextType);

export default useAuthContext;
export { AuthContext };
