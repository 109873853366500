import React from "react";
import { EyeBlindIcon } from "../../../icons";
import SPText from "../../../SPText/SPText";
import styles from "./Photos.module.css";

export default function Photos({ photos, visible = false, marginBottom }) {
  return (
    <div className={styles.container} styles={{ marginBottom }}>
      <SPText variant="h3">Feltöltött fotói</SPText>
      {photos && (
        <div className={styles.photos}>
          {visible === true
            ? photos.map((photo) => (
                <img
                  key={photo.id}
                  className={styles.photo}
                  src={photo.url}
                  alt=""
                />
              ))
            : [...(Array(photos.length).keys())].map(id => (
                <div key={id} className={styles.hidden}>
                  <EyeBlindIcon color="#FFECE1" size={45} />
                </div>
              ))}
        </div>
      )}
    </div>
  );
}
