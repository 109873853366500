import React from "react";
import SPText from "../SPText/SPText";
import colors from "../../config/colors";

function SPInputError({ error }) {
  if (!error) return null;
  return (
    <div style={{ marginTop: 4 }}>
      <SPText variant="comment-text" color={colors.light.marking.red}>
        {error}
      </SPText>
    </div>
  );
}

export default SPInputError;
