import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import postsApi from "../api/posts";

function usePost(id) {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  const getPost = async () => {
    setLoading(true);
    const res = await postsApi.getPost(id);
    if (res.ok) {
      setPost(res.data);
    }
    setLoading(false);
  };

  const deletePost = async (postId) => {
    setLoading(true);
    const res = await postsApi.deletePost(postId);
    if (res.ok) {
      toast.success("Törlés sikeres");
    } else {
      toast.error("Hiba történt, próbáld újra");
    }
    setLoading(false);
    return res;
  };

  useEffect(() => {
    const initSport = async () => {
      if (id) {
        await getPost();
      }
      setLoading(false);
    };
    initSport();
  }, []);

  return {
    loading,
    post,
    deletePost,
    getPost,
  };
}

export default usePost;
