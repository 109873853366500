import client, { requestConfig } from "./client";

const endpoint = "/logs";

const getLogs = ({ page = 0, pageSize = 20, filterData }) =>
  client.get(
    endpoint,
    {
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );

export default {
  getLogs,
};
