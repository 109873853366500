import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Card, Header, SPButton, SPInput, SPText } from "../../components";
import { CrossIcon, PictureIcon } from "../../components/icons";
import ImageFormCard from "../../components/ImageFormCard/ImageFormCard";
import colors from "../../config/colors";
import useSport from "../../hooks/useSport";
import styles from "./EditSportPage.module.css";


const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

function RecommendationHeader({ sport }) {
  const colStyle = { marginLeft: 48 };
  if (!sport || sport.status === "approved") return <div></div>;
  return (
    <div style={{ display: "flex" }}>
      <div>
        <SPText marginBottom={8} variant="h3">
          Felhasználó ajánlotta
        </SPText>
        <SPText color={colors.light.gray.main}>{sport.recommendedBy.id}</SPText>
      </div>
      <div style={colStyle}>
        <SPText marginBottom={8} variant="h3">
          Ajánlás neve
        </SPText>
        <SPText color={colors.light.gray.main}>{sport.name.hu}</SPText>
      </div>
      <div style={colStyle}>
        <SPText marginBottom={8} variant="h3">
          Ajánlás dátuma
        </SPText>
        <SPText color={colors.light.gray.main}>
          {dayjs(sport.createdAt).format("YYYY.MM.DD. HH:mm:ss")}
        </SPText>
      </div>
    </div>
  );
}

function EditSportPage() {
  const { sportId } = useParams();
  const { sport, loading, upsertSport, deleteSportCover } = useSport(sportId);
  const navigate = useNavigate();

  const [coversToDelete, setCoversToDelete] = useState([]);

  const yupSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string()
        .required("Angol név Kötelező")
        .min(2, "Angol névnek minimum 2 karakterből kell állnia"),
      hu: Yup.string()
        .required("Magyar név Kötelező")
        .min(2, "Magyar névnek minimum 2 karakterből kell állnia"),
    }),

    icon: Yup.mixed().nullable().required("Ikon Kötelező"),
    covers: Yup.array()
      .min(1, "Borítókép Kötelező")
      .required("Borítókép Kötelező"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: { en: "", hu: "" },
      icon: null,
      covers: [],
    },
    resolver: yupResolver(yupSchema),
  });

  const getUrl = (value) => {
    // if there is no profile picture (recommendations) react dies horribly -> check value as well not only type
    if (value && typeof value !== "string") {
      return URL.createObjectURL(value);
    }
    return value;
  };

  const onSubmitHandler = async ({ name, icon, covers }) => {
    // console.log({ name, icon, covers });
    const coversToUpload = Array.from(covers).filter(
      (cover) => typeof cover !== "string"
    );
    // delete covers
    coversToDelete.forEach(async (id) => {
      await deleteSportCover(sportId, id);
    });
    await upsertSport({
      sportId,
      name,
      status: "approved",
      icon,
      covers: coversToUpload,
      cb: (res) =>
        sportId === "new"
          ? navigate(`/sports/${res.data.id}`, { replace: true })
          : navigate(-1),
    });
  };

  const handleDeleteCover = async (url) => {
    const coverId = sport.coverImages.data.find(
      (coverObj) => coverObj.url === url
    ).id;
    // delete only on submit (until just store the info)
    setCoversToDelete([...coversToDelete, coverId]);
  };

  useEffect(() => {
    if (sport) {
      // console.log(sport);
      setValue("name", sport?.name);
      setValue("icon", sport?.icon);
      setValue(
        "covers",
        sport?.coverImages.data.map((obj) => obj.url)
      );
    }
  }, [sport]);

  return (
    <div className={styles.container}>
      <Header
        title={{ plain: "Sport", highlighted: "hozzáadása" }}
        withBack
        withModal
      />

      {/* USER RECOMMENDATION */}
      <RecommendationHeader sport={sport} />

      {(loading || control === undefined) && <div>loading...</div>}
      {!loading && (
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className={styles.formContainer}
        >
          <div className={styles.namesContainer}>
            <Controller
              name="name.en"
              control={control}
              render={({ field }) => (
                <SPInput
                  {...field}
                  type="text"
                  placeholder="Sport name.."
                  label="Angol neve"
                  error={errors?.name?.en?.message}
                />
              )}
            />
            <Controller
              name="name.hu"
              control={control}
              render={({ field }) => (
                <SPInput
                  {...field}
                  type="text"
                  placeholder="Sport neve.."
                  label="Magyar neve"
                  error={errors?.name?.hu?.message}
                />
              )}
            />
          </div>
          <div className={styles.iconUploadContainer}>
            <Controller
              name="icon"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <SPInput
                    value={value?.filename}
                    onChange={(e) => {
                      if (!ALLOWED_IMAGE_TYPES.includes(e.target.files[0].type)) return setError('icon', {
                        type: 'custom',
                        message: 'Csak .png és jpg fájlokat lehet feltölteni!'
                      });
                      onChange(e.target.files[0]);
                      setError('icon', null);
                    }}
                    accept={ALLOWED_IMAGE_TYPES}
                    type="file"
                    label="Ikon feltöltése"
                    placeholder="Feltöltés"
                    PrimaryIcon={PictureIcon}
                    className={styles.fileUpload}
                    error={errors?.icon?.message}
                  />
                  {value && (
                    <div className={styles.iconPreviewContainer}>
                      <Card
                        style={{
                          backgroundImage: value
                            ? `url(${getUrl(value)})`
                            : "none",
                          backgroundSize: "cover",
                        }}
                      ></Card>
                      <div
                        className={styles.iconPreviewCross}
                        onClick={() => onChange(null)}
                      >
                        <CrossIcon size={14} color="black" />
                      </div>
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className={styles.coverUploadContainer}>
            <Controller
              name="covers"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <SPInput
                    onChange={(e) => {
                      const numberOfImages = e.target.files.length - 1;
                      if (!ALLOWED_IMAGE_TYPES.includes(e.target.files[numberOfImages].type)) return setError('covers', {
                        type: 'custom',
                        message: 'Csak .png és jpg fájlokat lehet feltölteni!'
                      });
                      onChange([...value, ...e.target.files]);
                      setError('covers', null);
                    }}
                    accept={ALLOWED_IMAGE_TYPES}
                    type="file"
                    multiple
                    label="Borítókép feltöltése eseményekhez"
                    PrimaryIcon={PictureIcon}
                    className={styles.fileUpload}
                    error={errors?.covers?.message}
                  />
                  <div className={styles.covers}>
                    {value?.length === 0 && <ImageFormCard />}
                    {value?.length > 0 &&
                      Array.from(value).map((imageObj, index) => (
                        <ImageFormCard
                          key={index.toString()}
                          imageUrl={getUrl(imageObj)}
                          onRemove={() => {
                            onChange(
                              value.filter(
                                (image, filterIndex) => index !== filterIndex
                              )
                            );
                            if (typeof imageObj === "string") {
                              handleDeleteCover(imageObj);
                            }
                          }}
                        />
                      ))}
                  </div>
                </>
              )}
            />
          </div>
          <SPButton type="submit" title="Mentés" className={styles.button} />
        </form>
      )}
    </div>
  );
}

export default EditSportPage;
