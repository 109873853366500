import React from "react";
import styles from "./LabeledContainer.module.css";

import SPText from "../SPText/SPText";
import colors from "../../config/colors";

export default function LabeledContainer({
  label = "",
  text = "",
  textProps,
  children,
}) {
  return (
    <div className={styles.labeledContainer}>
      <SPText variant="h3">{label}</SPText>
      <div>
        {text && (
          <SPText
            variant="selection-text"
            color={colors.light.gray.gray1}
            {...textProps}
          >
            {text}
          </SPText>
        )}
        {!text && children && children}
      </div>
    </div>
  );
}
