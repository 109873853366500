import React from "react";
import { UserIcon } from "../../../icons";
import SPText from "../../../SPText/SPText";
import styles from "./Clubs.module.css";

export default function Clubs({ clubs }) {
  return (
    <div className={styles.container}>
      <SPText variant="h3">Klubok</SPText>
      <div className={styles.clubs}>
        {clubs &&
          clubs.map((club) => (
            <div className={styles.club} key={club.id}>
              <img className={styles.image} src={club.cover} alt={club.name} />
              <div className={styles.right}>
                <SPText variant="name-tag">{club.name}</SPText>
                <div className={styles["club-details"]}>
                  {club.sports?.data.map((sport) => (
                    <div className={styles.detail} key={sport.id}>
                      <img
                        src={sport.icon}
                        style={{ width: 18, height: 18 }}
                        alt=""
                      />
                      <SPText variant="comment-text">{sport.name}</SPText>
                    </div>
                  ))}
                  <div className={styles.detail}>
                    <UserIcon size={15} />
                    <SPText variant="comment-text">
                      {club.members}/{club.maxMembers}
                    </SPText>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
