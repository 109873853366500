import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card/Card";
import { CalendarIcon, UsersFilledIcon } from "../../../icons";
import SPText from "../../../SPText/SPText";

export default function EventsCard({ eventsPast, eventsFuture, ...props }) {
  const Header = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        width: "100%",
        borderBottom: "1px solid #CCCCCC",
        paddingBottom: 24,
        marginBottom: 24,
      }}
      {...props}
    >
      <CalendarIcon color="#333" size={24} />
      <SPText variant="event-club">Események</SPText>
    </div>
  );
  const style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  };
  return (
    <Card Header={Header}>
      <div>
        <div style={{ ...style, gap: 24 }}>
          <div style={style}>
            <SPText variant="event-club">Jövőbeli</SPText>
            <SPText variant="h2" color="#FF8A4A">
              {eventsFuture? eventsFuture.events : '-'}
            </SPText>
          </div>
          <div style={style}>
            <UsersFilledIcon size={18} />
            <SPText variant="h2" color="#FF8A4A">
              {eventsFuture ? eventsFuture.participants : '-'}
            </SPText>
          </div>
        </div>
        <div style={{ ...style, gap: 24 }}>
          <div style={style}>
            <SPText variant="event-club">Elmúlt</SPText>
            <SPText variant="h2" color="#ABABAB">
              {eventsPast ? eventsPast.events : '-'}
            </SPText>
          </div>
          <div style={style}>
            <UsersFilledIcon size={18} />
            <SPText variant="h2" color="#ABABAB">
              {eventsPast? eventsPast.participants : '-'}
            </SPText>
          </div>
        </div>
      </div>
    </Card>
  );
}

EventsCard.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
};
