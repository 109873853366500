export default function ArrowSmallRight({
  size = 24,
  color = "#FFF",
  ...props
}) {
  return (
    <>
      {color === "gradient" ? (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1 4.99982H11L7.71 1.70982C7.61627 1.61685 7.54188 1.50625 7.49111 1.38439C7.44034 1.26253 7.4142 1.13183 7.4142 0.999816C7.4142 0.867804 7.44034 0.737098 7.49111 0.615239C7.54188 0.49338 7.61627 0.382779 7.71 0.289816C7.89736 0.103565 8.15081 -0.000976562 8.415 -0.000976562C8.67919 -0.000976562 8.93264 0.103565 9.12 0.289816L13.41 4.58982C13.7856 4.96317 13.9978 5.47024 14 5.99982C13.9951 6.52592 13.7832 7.02892 13.41 7.39982L9.12 11.6998C9.02676 11.7924 8.9162 11.8657 8.79463 11.9156C8.67306 11.9654 8.54286 11.9908 8.41146 11.9904C8.28007 11.9899 8.15005 11.9636 8.02884 11.9129C7.90762 11.8621 7.79758 11.7881 7.705 11.6948C7.61242 11.6016 7.53911 11.491 7.48925 11.3694C7.4394 11.2479 7.41398 11.1177 7.41444 10.9863C7.41491 10.8549 7.44125 10.7249 7.49196 10.6037C7.54267 10.4824 7.61676 10.3724 7.71 10.2798L11 6.99982H1C0.734783 6.99982 0.480429 6.89446 0.292892 6.70692C0.105356 6.51939 0 6.26503 0 5.99982C0 5.7346 0.105356 5.48025 0.292892 5.29271C0.480429 5.10517 0.734783 4.99982 1 4.99982Z"
            fill="url(#paint1_linear_1742_1449)"
          />
          <defs>
            <linearGradient
              id="paint1_linear_1742_1449"
              x1={19.5}
              y1={7.99326}
              x2={0.833332}
              y2={7.99326}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EB3318" />
              <stop offset={1} stopColor="#FF8A4A" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1 4.99982H11L7.71 1.70982C7.61627 1.61685 7.54188 1.50625 7.49111 1.38439C7.44034 1.26253 7.4142 1.13183 7.4142 0.999816C7.4142 0.867804 7.44034 0.737098 7.49111 0.615239C7.54188 0.49338 7.61627 0.382779 7.71 0.289816C7.89736 0.103565 8.15081 -0.000976562 8.415 -0.000976562C8.67919 -0.000976562 8.93264 0.103565 9.12 0.289816L13.41 4.58982C13.7856 4.96317 13.9978 5.47024 14 5.99982C13.9951 6.52592 13.7832 7.02892 13.41 7.39982L9.12 11.6998C9.02676 11.7924 8.9162 11.8657 8.79463 11.9156C8.67306 11.9654 8.54286 11.9908 8.41146 11.9904C8.28007 11.9899 8.15005 11.9636 8.02884 11.9129C7.90762 11.8621 7.79758 11.7881 7.705 11.6948C7.61242 11.6016 7.53911 11.491 7.48925 11.3694C7.4394 11.2479 7.41398 11.1177 7.41444 10.9863C7.41491 10.8549 7.44125 10.7249 7.49196 10.6037C7.54267 10.4824 7.61676 10.3724 7.71 10.2798L11 6.99982H1C0.734783 6.99982 0.480429 6.89446 0.292892 6.70692C0.105356 6.51939 0 6.26503 0 5.99982C0 5.7346 0.105356 5.48025 0.292892 5.29271C0.480429 5.10517 0.734783 4.99982 1 4.99982Z"
            fill={color}
          />
        </svg>
      )}
    </>
  );
}
