import { useState } from "react";
import eventsApi from "../api/events";
import useApi from "./useApi";

function useEvents(filter, defaultPage = 0) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { request: getEvents } = useApi({
    call: eventsApi.getEvents,
    loading: [loading, setLoading],
    init: [initLoading, setInitLoading],
    initParams: {
      page: defaultPage,
      filterData: filter,
    },
    data: [data, setData],
  });

  return {
    initLoading,
    loading,
    events: data?.data,
    meta: data?.meta,
    getEvents,
  };
}

export default useEvents;
