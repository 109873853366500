import React, { Fragment } from "react";
import dayjs from "dayjs";
import { Badge, SPText } from "../../..";
import styles from "./Log.module.css";

const badgeColors = {
  info: "#006C8D",
  success: "#67B36A",
  danger: "#D94029",
  warning: "#FF8A4A",
};

const Log = ({ log }) => {
  const createdAt = dayjs(log?.createdAt).format("YYYY.MM.DD.");
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SPText variant="h1">Log</SPText>
        <SPText variant="h1" color="#FF8A4A">
          adatlap
        </SPText>
      </div>
      <SPText variant="selection-text" textAlign="center" marginBottom={8}>
        {createdAt}
      </SPText>
      {log.type && (
        <Badge
          variant="long"
          title={log.type}
          color={badgeColors[log.variant]}
          textColor={badgeColors[log.variant]}
          marginBottom={32}
          outlined
        />
      )}
      <div className={styles.row} style={{ marginBottom: 8 }}>
        <SPText variant="h3" style={{ flex: 1 }}>
          Felhasználó ID
        </SPText>
        <SPText variant="h3">Log ID</SPText>
      </div>
      <div className={styles.row} style={{ marginBottom: 24 }}>
        <SPText variant="selection-text">{log?.createdBy?.id}</SPText>
        <SPText variant="selection-text">{log?.id}</SPText>
      </div>
      {log?.details?.map((detail, index) => (
        <Fragment key={index}>
          <div style={{ width: "90%", marginBottom: 8 }}>
            <SPText variant="h3">{detail.label}</SPText>
          </div>
          <div style={{ width: "90%", marginBottom: 8 }}>
            <SPText variant="h3">{detail.value}</SPText>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Log;

export { badgeColors };
