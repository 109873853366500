import * as React from "react";

function PencilIcon({ size = 24, color = "#fff", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.82 2.431a3.184 3.184 0 00-4.5 0L3.037 16.714A4.363 4.363 0 001.75 19.82v1.803a.878.878 0 00.878.878h1.803a4.361 4.361 0 003.105-1.286L21.82 6.93a3.185 3.185 0 000-4.499zM6.294 19.973a2.654 2.654 0 01-1.863.77h-.925v-.924a2.617 2.617 0 01.772-1.863l10.84-10.84 2.02 2.02L6.294 19.973zM20.577 5.688l-2.2 2.202-2.02-2.016 2.2-2.201a1.426 1.426 0 112.016 2.02l.004-.005z"
        fill={color}
      />
    </svg>
  );
}

export default PencilIcon;
