import React from 'react';
import PropTypes from 'prop-types';
import SPText from '../SPText/SPText';
import styles from './SPList.module.css';

/** SPList
 * 
 * @param {array} items an array of objects
 * @param {array} except an array of keys which we do not want to be displayed
 * @param {React.ReactElement} Action element that we need to render for every list item
 * @param {object} Components an object of Components, where the key refers for the column name
 * @returns {React.ReactElement} SPList
 */

export default function SPList({ items, except = [], Action, Components = [] }) {
    return (
        <table className={styles.table}>
          <tbody>
            {items.map(item => {
              const columns = Object.keys(item)
                .filter(column => !except.includes(column));
              return (
                <tr key={item.id}>
                  {
                    columns.map(column => {
                      const Component = Components[column] || null;
                      return (
                        <td key={column}>
                          {Component ?
                            <Component text={item[column]} /> :
                            <SPText variant="selection-text">
                                {item[column]}
                            </SPText>
                          }
                        </td>
                      ); 
                    })
                  }
                  {Action &&
                    <td>
                      <Action id={item.id} />
                    </td>
                  }
                </tr>
              );
            })}
        </tbody>
      </table>
    );
}

SPList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  except: PropTypes.arrayOf(PropTypes.string),
  Action: PropTypes.elementType,
  Components: PropTypes.objectOf(PropTypes.func)
};