import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Header,
  SPButton,
  SPText,
  SPList,
} from '../../components';
import {
  PlusIcon,
  ArrowSmallRight,
} from '../../components/icons';
import styles from './DocsPage.module.css';

function DocsPage() {
  const navigate = useNavigate();
  const Action = ({id}) => (
    <SPButton
      shape="icon"
      Icon={ArrowSmallRight}
      IconProps={{color: '#FFFFFF'}}
      onClick={() => navigate(`/docs/${id}`)}
    />
  );
  const docs = [
    {
      id: '1',
      name: 'Általános Szerződési Feltételek',
      date: '2022/01/09',
      active: false,
    },
    {
      id: '2',
      name: 'Adatkezelési Tájékoztató',
      date: '2022/01/09',
      active: false,
    },
    {
      id: '3',
      name: 'Általános Szerződési Feltételek',
      date: '2022/05/09',
      active: false,
    },
    {
      id: '4',
      name: 'Adatkezelési Tájékoztató',
      date: '2022/05/09',
      active: false,
    },
    {
      id: '5',
      name: 'Általános Szerződési Feltételek',
      date: '2022/09/09',
      active: true,
    },
    {
      id: '6',
      name: 'Adatkezelési Tájékoztató',
      date: '2022/09/09',
      active: true,
    }
  ];
  const components = {
    name: ({text}) => <SPText variant="name-tag" color="#333333">{text}</SPText>,
    date: ({text}) => {
      const date = new Date(text).toLocaleDateString('hu-HU');
      return <SPText variant="selection-text" color="#ABABAB">{date}</SPText>;
    }
  }
  const List = ({items}) => (
    <SPList
      items={items}
      except={['id', 'active']}
      Components={components}
      Action={Action}
    />
  )
  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: 'Jogi',
          highlighted: 'dokumentumok',
        }}
        Action={(
          <SPButton
            title='Új dokumentum feltöltése'
            Icon={PlusIcon}
            IconProps={{color: '#FFFFFF'}}
            onClick={() => navigate('/docs/create')}
          />
        )}
      />
      <SPText variant="h3" marginBottom={16}>
        Aktuális verzió
      </SPText>
        <List items={docs.filter(({ active }) => active === true)} />
      <SPText variant="h3" marginBottom={16}>
        Korábbi verziók
      </SPText>
        <List items={docs.filter(({ active }) => active === false)} />
    </div>
  );
}

export default DocsPage;
