export default function UsersFilledIcon({
  size = 24,
  color = "#333",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.125 10.25C5.45749 10.25 4.80497 10.0521 4.24995 9.68121C3.69493 9.31036 3.26235 8.78326 3.00691 8.16656C2.75146 7.54986 2.68462 6.87126 2.81485 6.21657C2.94507 5.56189 3.26651 4.96052 3.73851 4.48852C4.21052 4.01651 4.81188 3.69508 5.46657 3.56485C6.12126 3.43463 6.79986 3.50146 7.41656 3.75691C8.03326 4.01235 8.56036 4.44494 8.93121 4.99995C9.30206 5.55497 9.5 6.20749 9.5 6.875C9.49901 7.7698 9.14311 8.62767 8.51039 9.26039C7.87767 9.89311 7.0198 10.249 6.125 10.25ZM11 18.5H1.25C1.05109 18.5 0.860322 18.421 0.71967 18.2803C0.579018 18.1397 0.5 17.9489 0.5 17.75V17.375C0.5 15.8832 1.09263 14.4524 2.14752 13.3975C3.20242 12.3426 4.63316 11.75 6.125 11.75C7.61684 11.75 9.04758 12.3426 10.1025 13.3975C11.1574 14.4524 11.75 15.8832 11.75 17.375V17.75C11.75 17.9489 11.671 18.1397 11.5303 18.2803C11.3897 18.421 11.1989 18.5 11 18.5ZM13.625 7.25C12.9575 7.25 12.305 7.05206 11.75 6.68121C11.1949 6.31036 10.7624 5.78326 10.5069 5.16656C10.2515 4.54986 10.1846 3.87126 10.3148 3.21657C10.4451 2.56189 10.7665 1.96052 11.2385 1.48852C11.7105 1.01651 12.3119 0.695076 12.9666 0.564851C13.6213 0.434626 14.2999 0.501462 14.9166 0.756908C15.5333 1.01235 16.0604 1.44494 16.4312 1.99995C16.8021 2.55497 17 3.20749 17 3.875C16.999 4.7698 16.6431 5.62767 16.0104 6.26039C15.3777 6.89311 14.5198 7.24901 13.625 7.25ZM12.5592 8.76575C11.8607 8.85947 11.1891 9.09643 10.5864 9.46179C9.98368 9.82715 9.46293 10.313 9.05675 10.889C10.7374 11.6522 12.058 13.0357 12.7423 14.75H17.75C17.9489 14.75 18.1397 14.671 18.2803 14.5303C18.421 14.3897 18.5 14.1989 18.5 14V13.9715C18.4992 13.2239 18.339 12.4851 18.0299 11.8043C17.7209 11.1236 17.2702 10.5166 16.708 10.0239C16.1457 9.53122 15.4848 9.16413 14.7694 8.94717C14.0539 8.7302 13.3005 8.66836 12.5592 8.76575Z"
        fill={color}
      />
    </svg>
  );
}
