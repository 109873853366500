import { useState } from "react";
import usersApi from "../api/users";
import useApi from "./useApi";

function useUsers(filter, defaultPage = 0) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { request: getUsers } = useApi({
    call: usersApi.getUsers,
    loading: [loading, setLoading],
    init: [initLoading, setInitLoading],
    initParams: {
      page: defaultPage,
      filterData: filter,
    },
    data: [data, setData],
  });

  const { request: deleteUser } = useApi({
    call: usersApi.deleteUser,
    loading: [loading, setLoading],
    init: null,
    initParams: {},
    data: null,
  });

  return {
    initLoading,
    loading,
    users: data?.data,
    meta: data?.meta,
    getUsers,
    deleteUser,
  };
}

export default useUsers;
