import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  SPButton,
  SPInput,
  SPModal,
  SPTable,
  SPText,
} from "../../components";
import { ArrowSmallRight, SearchIcon, TrashIcon } from "../../components/icons";
import usePosts from "../../hooks/usePosts";
import useQuery from "../../hooks/useQuery";
import styles from "./PostsPage.module.css";

function prepareFilterData(data) {
  return { s: data.s || null };
}

function PostsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState({
    s: location?.state?.s || "",
  });
  const { loading, posts, meta, getPosts, deletePost } = usePosts(
    prepareFilterData(filter),
    location?.state?.page || 0
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const deleteSelectedPost = async () => {
    await deletePost(selectedPostId);
    await getPosts({ filterData: prepareFilterData(filter), page: meta.page });
    setShowModal(false);
    setSelectedPostId(null);
  };

  const { queryChanging } = useQuery({
    query: filter,
    timeout: 500,
    request: ({ nextQuery }) =>
      posts &&
      getPosts({
        page: 0,
        filterData: prepareFilterData(nextQuery),
      }),
  });

  const Action = ({ item }) => (
    <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
      <SPButton
        shape="icon"
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 22 }}
        onClick={() => {
          setShowModal(true);
          setSelectedPostId(item.id);
        }}
      />
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        onClick={() =>
          navigate(`/posts/${item.id}`, {
            state: {
              ...filter,
              page: meta.page,
            },
          })
        }
      />
    </div>
  );

  const columns = [
    {
      title: "Azonosító",
      field: "id",
    },
    {
      title: "Klub név",
      field: "club.name",
    },
    {
      title: "Bejegyzés létrehozásának dátuma",
      field: "createdAt",
      Component: ({ column }) => {
        const date = new Date(column).toLocaleDateString("hu-HU");
        return <SPText variant="selection-text">{date}</SPText>;
      },
    },
  ];

  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Bejegyzések",
          highlighted: "listája",
        }}
      />
      <SPModal visible={showModal} onCancel={() => setShowModal(false)}>
        <SPText variant="h1" marginBottom={24} textAlign="center">
          Bejegyzés törlése
        </SPText>
        <SPText variant="selection-text" marginBottom={24}>
          Törlés után már nem fog megjelenni ez a bejegyzés a klubban.
        </SPText>
        <SPButton title="Törlés" onClick={deleteSelectedPost} />
      </SPModal>
      <div className={styles["search-container"]}>
        <SPInput
          value={filter.s}
          onChange={(e) => setFilter({ ...filter, s: e.target.value })}
          placeholder="Klub ID, klub név..."
          PrimaryIcon={!queryChanging && SearchIcon}
        />
      </div>
      {loading && <div>Loading...</div>}
      {posts && (
        <SPTable
          items={posts}
          meta={meta}
          onPageChange={(page) =>
            getPosts({ page, filterData: prepareFilterData(filter) })
          }
          columns={columns}
          Action={Action}
        />
      )}
    </div>
  );
}

export default PostsPage;
