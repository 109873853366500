import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, SPButton, SPModal, SPText } from "../../components";
import { PlusIcon, TrashIcon } from "../../components/icons";
import {
  ActiveUsersCard,
  EventsCard,
  SportRequestsCard,
} from "../../components/pages/DashboardPage";
import useMetrics from "../../hooks/useMetrics";
import useSport from "../../hooks/useSport";
import useSports from "../../hooks/useSports";
import styles from "./DashboardPage.module.css";

function DashboardPage() {
  const { usersActive, eventsFuture, eventsPast } = useMetrics();
  const sportFilter = {
    status: "registered",
    "pagination[page]": 0,
  };
  const { sports, getSports } = useSports(sportFilter);
  const { deleteSport, loading: loadingSport } = useSport();
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();

  const deleteSportHandler = async () => {
    const id = selectedId;
    await deleteSport(id);
    await getSports({ page: 0, filterData: sportFilter });
    setSelectedId(null);
  };

  const Action = ({ item, ...props }) => (
    <div style={{ display: "flex", gap: 16 }} {...props}>
      <SPButton
        variant="secondary"
        shape="icon"
        onClick={() => {
          setSelectedId(item.id);
        }}
        Icon={TrashIcon}
        IconProps={{ size: 24 }}
        disabled={loadingSport}
      />
      <SPButton
        shape="icon"
        Icon={PlusIcon}
        onClick={() => navigate(`/sports/${item.id}`)}
      />
    </div>
  );
  return (
    <div className={styles.container}>
      <Header title={{ plain: "Szia,", highlighted: "Viktor!" }} />
      <div className={styles.grid}>
        <div>
          <SportRequestsCard items={sports} Action={Action} />
        </div>
        <div className={styles.right}>
          <ActiveUsersCard users={usersActive} />
          <EventsCard eventsFuture={eventsFuture} eventsPast={eventsPast} />
        </div>
      </div>

      <SPModal
        visible={!!selectedId}
        onCancel={() => {
          setSelectedId(null);
        }}
      >
        <SPText variant="h1" textAlign="center">
          Sport törlése
        </SPText>
        <SPText>
          Miután törölted, a felhasználók adatlapjáról is törlésre kerül ez a
          sport.
        </SPText>
        <SPButton title="törlés" onClick={deleteSportHandler} />
      </SPModal>
    </div>
  );
}

export default DashboardPage;
