import React, { useState } from "react";
import dayjs from "dayjs";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Header,
  SPCheckbox,
  LabeledContainer,
  SPText,
  SPButton,
  SPModal,
} from "../../components";
import { Head, Sports, Clubs, Photos } from "../../components/pages/UserPage";
import useUser from "../../hooks/useUser";
import useUsers from "../../hooks/useUsers";
import styles from "./UserPage.module.css";
import useToast from "../../hooks/useToast";

function UserPage() {
  const { deleteError, deleteSuccess } = useToast();
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { deleteUser, loading: deleteLoading } = useUsers(0);
  const { user, loading, initLoading } = useUser({ id: userId });
  const [visible, setVisible] = useState(location?.state?.infoVisible || false);
  const [showModal, setShowModal] = useState(false);

  const handleUserDelete = async () => {
    if (!userId) return;
    await deleteUser({
      onSuccess: () => {
        deleteSuccess();
        navigate("/users");
      },
      onError: () => deleteError(),
      id: userId,
    });
  };
  const hide = (str) => (str ? "*".repeat(str.length) : str);
  const dateFormat = (str) => dayjs(str).format("YYYY.MM.DD.");

  const Details = () => (
    <div className={styles.detailsContainer}>
      <LabeledContainer
        text={visible ? user.email : hide(user.email)}
        label="E-mail cím"
      />
      <LabeledContainer
        text={visible ? dateFormat(user.birthday) : "****.**.**."}
        label="Születésnap"
      />
      <LabeledContainer
        text={user.gender === "male" ? "Férfi" : "Nő"}
        label="Neme"
      />
      <LabeledContainer
        text={user.language === "hu" ? "Magyar" : "Angol"}
        label="Nyelv"
      />
    </div>
  );

  const Register = () => (
    <div className={styles.registerContainer}>
      <LabeledContainer
        text={dateFormat(user.createdAt)}
        label="Regisztáció dátuma"
      />
      <LabeledContainer
        text={user.suspensionFrom ? dateFormat(user.suspensionFrom) : "-"}
        label="Felfüggesztés dátuma"
      />
    </div>
  );

  if (initLoading) return <div></div>;

  return (
    <div className={styles.container}>
      <SPModal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <SPText variant="h1" textAlign="center">
          Felhasználó törlése
        </SPText>
        <SPText variant="selection-text">
          Törlés után ez a felhasználó nem lesz elérhető az applikációban, és új
          fiókkal tud majd csak regisztrálni.
        </SPText>
        <SPButton
          disabled={deleteLoading}
          title="törlés"
          onClick={handleUserDelete}
        />
      </SPModal>
      <Header
        title={{ plain: "Felhasználó", highlighted: "adatlap" }}
        className={styles.header}
        withBack
        backNavPath="/users"
        backNavOptions={{
          state: {
            infoVisible: visible,
            page: location?.state?.page,
            search: location?.state?.search,
          },
        }}
      />
      <SPCheckbox
        label="Felhasználó adatok láthatósága"
        value={visible}
        onChange={setVisible}
        className={styles.showInformation}
      />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.content}>
          <Head
            visible={visible}
            user={user}
            marginBottom={32}
            onClickDeleteButton={() => setShowModal(true)}
          />
          <div className={styles.grid}>
            <div className={styles.leftContainer}>
              <Details />
              <LabeledContainer
                text={visible ? user.story : hide(user.story)}
                label="Története"
              />
              <Sports sports={user.favouriteSports.data} />
              <Clubs clubs={user.clubs?.data} />
              <Register />
            </div>
            <Photos visible={visible} photos={user.photos.data} />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPage;
