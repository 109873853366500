import React from "react";
import SPText from "../../../SPText/SPText";
import Badge from "../../../Badge/Badge";
import SPButton from "../../../SPButton/SPButton";
import { PencilIcon, TrashIcon } from "../../../icons";
import styles from "./Head.module.css";

const badgeTypes = {
    public: {
        color: '#67B36A',
        title: 'Publikus',
    },
    'public-closed': {
        color: '#006C8D',
        title: 'Publikus/Zárt',
    },
    closed: {
        color: '#D94029',
        title: 'Zárt',
    },
};

export default function Head({
  club,
  marginBottom,
  style = {},
  onClickDelete,
  onClickModify,
  ...props
}) {
  return (
    <div
      className={styles.container}
      style={{ ...style, marginBottom }}
      {...props}
    >
      <div className={styles.left}>
        <div>
            <img
                src={club?.cover}
                className={styles.picture} alt=""
            />
        </div>
        <div className={styles.details}>
          <div>
            <SPText variant="event-club">
              {club?.name}
            </SPText>
            <SPText variant="selection-text" color="#ABABAB">
              {club?.id}
            </SPText>
          </div>
          <div>
            {club?.type && (<Badge
              variant="long"
              title={badgeTypes[club.type].title}
              color={badgeTypes[club.type].color}
            />)}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <SPButton
            variant="primary"
            Icon={PencilIcon}
            IconProps={{size: 21}}
            title="Módosítás"
            onClick={onClickModify}
        />
        <SPButton
            variant="secondary"
            shape='icon'
            Icon={TrashIcon}
            IconProps={{ size: 24 }}
            onClick={onClickDelete}
        />
      </div>
    </div>
  );
}
