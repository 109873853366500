import React from "react";
import SPButton from "../SPButton/SPButton";
import styles from "./SPModal.module.css";

export default function SPModal({
  visible,
  children,
  noCancelButton,
  onCancelTitle = "Mégse",
  onCancel
}) {
  return (
    <div
      className={styles.container}
      style={{ display: visible ? "flex" : "none" }}
    >
      <div className={styles.backdrop} onClick={onCancel}></div>
      <div className={styles.content}>
        {children}
        {!noCancelButton && (
          <SPButton
            variant="secondary"
            onClick={onCancel}
            title={onCancelTitle}
            style={{ width: "100%" }}
          />
        )}
      </div>
    </div>
  );
}
