export default function HouseIcon({ size = 24, color = "#CCCCCC", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.121 9.56899L15.536 1.98299C14.5973 1.04698 13.3257 0.521362 12 0.521362C10.6744 0.521362 9.40277 1.04698 8.46401 1.98299L0.879012 9.56899C0.599438 9.84677 0.377782 10.1773 0.226895 10.5414C0.0760072 10.9055 -0.0011104 11.2959 1.20795e-05 11.69V21.507C1.20795e-05 22.3026 0.316083 23.0657 0.878692 23.6283C1.4413 24.1909 2.20436 24.507 3.00001 24.507H21C21.7957 24.507 22.5587 24.1909 23.1213 23.6283C23.6839 23.0657 24 22.3026 24 21.507V11.69C24.0011 11.2959 23.924 10.9055 23.7731 10.5414C23.6222 10.1773 23.4006 9.84677 23.121 9.56899ZM15 22.507H9.00001V18.573C9.00001 17.7773 9.31608 17.0143 9.87869 16.4517C10.4413 15.8891 11.2044 15.573 12 15.573C12.7957 15.573 13.5587 15.8891 14.1213 16.4517C14.6839 17.0143 15 17.7773 15 18.573V22.507ZM22 21.507C22 21.7722 21.8947 22.0266 21.7071 22.2141C21.5196 22.4016 21.2652 22.507 21 22.507H17V18.573C17 17.2469 16.4732 15.9751 15.5355 15.0375C14.5979 14.0998 13.3261 13.573 12 13.573C10.6739 13.573 9.40216 14.0998 8.46448 15.0375C7.5268 15.9751 7.00001 17.2469 7.00001 18.573V22.507H3.00001C2.7348 22.507 2.48044 22.4016 2.29291 22.2141C2.10537 22.0266 2.00001 21.7722 2.00001 21.507V11.69C2.00094 11.425 2.1062 11.171 2.29301 10.983L9.87801 3.39999C10.4417 2.83892 11.2047 2.52393 12 2.52393C12.7953 2.52393 13.5583 2.83892 14.122 3.39999L21.707 10.986C21.8931 11.1732 21.9983 11.426 22 11.69V21.507Z"
        fill={color}
      />
    </svg>
  );
}
