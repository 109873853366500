import React from "react";
import styles from "./Layout.module.css";
import Navbar from "./Navbar";

function Layout({ children }) {
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
}

const withLayout = (Component) => <Layout>{Component}</Layout>;

export default Layout;
export { withLayout };
