import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Badge,
  Header,
  SPButton,
  SPInput,
  SPModal,
  SPTable,
  SPText,
} from "../../components";
import {
  ArrowSmallRight,
  PlusIcon,
  SearchIcon,
  TrashIcon,
} from "../../components/icons";
import useClub from "../../hooks/useClub";
import useClubs from "../../hooks/useClubs";
import useQuery from "../../hooks/useQuery";
import useToast from "../../hooks/useToast";
import styles from "./ClubsPage.module.css";

const badgeTypes = {
  public: {
    color: "#67B36A",
    title: "Publikus",
  },
  "public-closed": {
    color: "#006C8D",
    title: "Publikus/Zárt",
  },
  closed: {
    color: "#D94029",
    title: "Zárt",
  },
};

const DeleteClubModal = ({ visible, onCancel, onDelete, loading }) => (
  <SPModal visible={visible} onCancel={onCancel}>
    <SPText variant="h1" marginBottom={24}>
      Klub törlése
    </SPText>
    <SPText variant="selection-text" marginBottom={24}>
      Törlés után ez a klub már nem fog megjelenni az applikációban, és
      értesítés megy a tagoknak a klub megszűnéséről.
    </SPText>
    <SPButton disabled={loading} title="Törlés" onClick={onDelete} />
  </SPModal>
);

const prepareFilterData = (data) => ({
  s: data.search || null,
});

function ClubsPage() {
  const { deleteError, deleteSuccess } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState({
    search: location?.state?.search || "",
  });
  const { initLoading, clubs, meta, getClubs } = useClubs(
    prepareFilterData(filter),
    location?.state?.page || 0
  );
  const { deleteClub, loading } = useClub();
  const [clubToDelete, setClubToDelete] = useState(null);

  const { queryChanging } = useQuery({
    query: filter,
    timeout: 500,
    request: ({ nextQuery }) =>
      clubs &&
      getClubs({
        page: 0,
        filterData: prepareFilterData(nextQuery),
      }),
  });

  async function deleteHandler() {
    await deleteClub({
      id: clubToDelete,
      onSuccess: () => {
        deleteSuccess();
        setClubToDelete(null);
        getClubs({ page: meta.page, filterData: prepareFilterData(filter) });
      },
      onError: () => {
        deleteError();
      },
    });
  }

  const Action = ({ item }) => (
    <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
      <SPButton
        shape="icon"
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 22 }}
        onClick={() => setClubToDelete(item.id)}
      />
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        onClick={() =>
          navigate(`/clubs/${item.id}`, {
            state: { ...filter, page: meta.page },
          })
        }
      />
    </div>
  );

  const columns = [
    {
      title: "Azonosító",
      field: "id",
    },
    {
      title: "Klub neve",
      field: "name",
    },
    {
      title: "Klub típus",
      field: "type",
      Component: ({ column }) => (
        <Badge
          variant="long"
          title={badgeTypes[column].title}
          color={badgeTypes[column].color}
        />
      ),
    },
  ];

  if (initLoading) return <div></div>;

  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Klubbok",
          highlighted: "listája",
        }}
        Action={
          <SPButton
            title="Új klub"
            Icon={PlusIcon}
            IconProps={{ color: "#FFFFFF" }}
            onClick={() => navigate("/clubs/new/edit")}
          />
        }
      />
      <div className={styles["search-container"]}>
        <SPInput
          placeholder="Klub név, e-mail cím..."
          PrimaryIcon={!queryChanging && SearchIcon}
          value={filter.search}
          onChange={(e) => setFilter({ ...filter, search: e.target.value })}
        />
      </div>
      {clubs && (
        <SPTable
          items={clubs}
          meta={meta}
          onPageChange={(page) =>
            getClubs({ page, filterData: prepareFilterData(filter) })
          }
          columns={columns}
          Action={Action}
        />
      )}
      <DeleteClubModal
        loading={loading}
        visible={clubToDelete}
        onCancel={() => setClubToDelete(null)}
        onDelete={deleteHandler}
      />
    </div>
  );
}

export default ClubsPage;
export { DeleteClubModal };
