import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Header, SPText, SPButton, SPModal } from "../../components";
import { Head, Details } from "../../components/pages/ClubPage";
import useClub from "../../hooks/useClub";
import useToast from "../../hooks/useToast";
import styles from "./ClubsPage.module.css";

function ClubPage() {
  const { deleteError, deleteSuccess } = useToast();
  const { clubId } = useParams();
  const { club, loading, deleteClub } = useClub(clubId);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const deleteClubHandler = async () => {
    await deleteClub({
      id: clubId,
      onSuccess: () => {
        deleteSuccess();
        navigate("/clubs");
        setShowModal(false);
      },
      onError: () => {
        deleteError();
      },
    });
  };
  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Klub",
          highlighted: "megtekintése",
        }}
        withBack
        backNavPath={"/clubs"}
        backNavOptions={{ state: location?.state }}
      />
      {loading && <div>Loading...</div>}
      {!loading && (
        <>
          <SPModal visible={showModal} onCancel={() => setShowModal(false)}>
            <SPText variant="h1" textAlign="center">
              Klub törlése
            </SPText>
            <SPText>
              Miután törölted, a felhasználók adatlapjáról is törlésre kerül ez
              a klub.
            </SPText>
            <SPButton
              disabled={loading}
              title="törlés"
              onClick={deleteClubHandler}
            />
          </SPModal>
          <Head
            club={club}
            onClickModify={() => navigate(`/clubs/${clubId}/edit`)}
            onClickDelete={() => setShowModal(true)}
            marginBottom={32}
          />
          <Details club={club} marginBottom={32} />
          <SPText variant="h3" color="#33333" marginBottom={8}>
            Klub leírása
          </SPText>
          <SPText variant="selection-text" color="#ABABAB">
            {club?.description}
          </SPText>
        </>
      )}
    </div>
  );
}

export default ClubPage;
