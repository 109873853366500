import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/auth";

function AuthRoutes() {
  console.log("auth routes");
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />} />
        {/* "No Match Route" */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </React.Fragment>
  );
}

export default AuthRoutes;
