import React, { useState } from "react";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import { CrossIcon } from "../icons";
import SPInput from "../SPInput/SPInput";
import SPText from "../SPText/SPText";
import styles from "./QuerySelect.module.css";

const _ = require("lodash");

const Option = ({ children, onClick }) => (
  <SPText
    variant="comment-text"
    className={styles.option}
    onMouseDown={onClick}
  >
    {children}
  </SPText>
);

const Selected = ({ children, onClick }) => (
  <div className={styles.selectedItem}>
    <CrossIcon onClick={onClick} cursor="pointer" size={10} color="#D94029" />
    <div style={{ marginRight: 4 }}></div>
    <SPText variant="comment-text">{children}</SPText>
  </div>
);

function QuerySelect({
  value,
  onChange,
  timeout = 500,
  request,
  labelField = "label",
  fallbackLabelField = labelField,
  keyField = "id",
  label,
  placeholder = "Search...",
  singleSelect = false,
  initFilterData = {},
  PrimaryIcon,
  primaryIconProps,
}) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [focused, setFocused] = useState(null);
  const { request: queryRequest } = useApi({
    call: request,
    loading: [loading, setLoading],
    data: [data, setData],
  });

  const { queryChanging } = useQuery({
    query: search,
    timeout,
    request: ({ nextQuery }) =>
      queryRequest({
        page: 0,
        filterData: { ...initFilterData, s: nextQuery || null },
      }),
  });

  const deleteValue = (v) => {
    if (!singleSelect) {
      const newValue = value.filter((_v) => _v.id !== v.id);
      onChange && onChange([...newValue]);
    } else {
      onChange && onChange(null);
    }
  };

  const addValue = (v) => {
    if (onChange) {
      singleSelect ? onChange(v) : onChange([...value, v]);
    }
    setSearch("");
  };

  return (
    <div className={styles.container}>
      <SPInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onFocusChanged={setFocused}
        label={label}
        placeholder={placeholder}
        PrimaryIcon={PrimaryIcon}
        primaryIconProps={primaryIconProps}
      />
      <div>{queryChanging}</div>
      {focused && (
        <div className={styles.options}>
          {data?.data.length > 0 ? (
            data.data
              .filter((option) => {
                if (!value) return true;
                if (singleSelect) {
                  return value[keyField] !== option[keyField];
                }
                return !value
                  ?.map((v) => v[keyField])
                  .includes(option[keyField]);
              })
              .map((option) => (
                <Option
                  onClick={(e) => {
                    !singleSelect && e.preventDefault();
                    addValue(option);
                  }}
                  key={_.get(option, keyField)}
                >
                  {_.get(option, labelField) ||
                    _.get(option, fallbackLabelField)}
                </Option>
              ))
          ) : (
            <SPText>Nincs találat...</SPText>
          )}
        </div>
      )}
      <div className={styles.selected}>
        {!singleSelect
          ? value?.map((v) => (
              <Selected key={_.get(v, keyField)} onClick={() => deleteValue(v)}>
                {_.get(v, labelField) || _.get(v, fallbackLabelField)}
              </Selected>
            ))
          : value && (
              <Selected onClick={() => deleteValue(value)}>
                {_.get(value, labelField) || _.get(value, fallbackLabelField)}
              </Selected>
            )}
      </div>
    </div>
  );
}

export default QuerySelect;
