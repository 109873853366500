import { toast } from "react-toastify";

export default function useToast() {
  function updateSuccess(customMessage = "") {
    toast.success(customMessage || "Mentés sikeres");
  }
  function updateError(customMessage = "") {
    toast.error(customMessage || "Hiba a mentés során, próbáld újra");
  }

  function createSuccess(customMessage = "") {
    toast.success(customMessage || "Mentés sikeres");
  }
  function createError(customMessage = "") {
    toast.error(customMessage || "Hiba a mentés során, próbáld újra");
  }

  function deleteSuccess(customMessage = "") {
    toast.success(customMessage || "Törlés sikeres");
  }
  function deleteError(customMessage = "") {
    toast.error(customMessage || "Hiba a törlés során, próbáld újra");
  }

  return {
    toast,
    updateError,
    updateSuccess,
    createError,
    createSuccess,
    deleteError,
    deleteSuccess,
  };
}
