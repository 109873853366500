import client, { requestConfig } from "./client";

const endpoint = "/clubs";

const getClubs = ({ page = 0, pageSize = 20, filterData }) =>
  client.get(
    endpoint,
    {
      as: "admin",
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );
const getClub = ({ id }) =>
  client.get(
    `${endpoint}/${id}`,
    { as: "admin" },
    { headers: { ...requestConfig().headers } }
  );
const create = ({ name, type, maxMembers, description, admin, sports }) =>
  client.post(
    `${endpoint}?as=admin`,
    { name, type, maxMembers, description, admin, sports },
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );
const update = ({ id, name, type, maxMembers, description, admin, sports }) =>
  client.patch(
    `${endpoint}/${id}?as=admin`,
    { name, type, maxMembers, description, admin, sports },
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );
const deleteClub = ({ id }) =>
  client.delete(
    `${endpoint}/${id}`,
    { as: "admin" },
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );

const uploadCover = ({ id, data }) =>
  client.post(`${endpoint}/${id}/cover?as=admin`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...requestConfig().headers,
    },
  });
export default {
  create,
  update,
  getClubs,
  getClub,
  deleteClub,
  uploadCover,
};
