import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Layout/Header";
import useEvent from "../../hooks/useEvent";
import Head from "../../components/pages/EventPage/Head/Head";
import Details from "../../components/pages/EventPage/Details/Details";
import Description from "../../components/pages/EventPage/Description/Description";
import styles from "./EventPage.module.css";
import Footer from "../../components/pages/EventPage/Footer/Footer";
import { DeleteEventModal } from "./EventsPage";
import useToast from "../../hooks/useToast";

function EventPage() {
  const { deleteSuccess, deleteError } = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filter, setFilter] = useState({ featured: false });
  const { initLoading, event, patchEvent } = useEvent(params.eventId);

  useEffect(() => {
    if (event) {
      setFilter({ featured: event.featured });
    }
  }, [event]);

  if (initLoading) {
    return (
      <div className={styles.container}>
        <Header
          title={{ plain: "Esemény", highlighted: "megtekintése" }}
          withBack
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Header
        title={{ plain: "Esemény", highlighted: "megtekintése" }}
        withBack
        backNavPath={"/events"}
        backNavOptions={{ state: location?.state }}
      />
      <div className={styles.content}>
        <Head
          featured={filter.featured}
          setFeatured={(featured) => {
            patchEvent({ id: params.eventId, data: { featured } });
            toast.success("Sikeres mentés!", {
              position: "top-right",
              autoClose: 5000,
            });
          }}
          event={event}
          marginBottom={32}
          onClickDeleteButton={() => setShowDeleteModal(true)}
        />
        <Details event={event} marginBottom={32} />
        <Description event={event} marginBottom={32} />
        <Footer event={event} />
      </div>

      <DeleteEventModal
        event={event}
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onSuccess={() => {
          deleteSuccess();
          navigate("/events");
        }}
        onError={() => {
          deleteError();
        }}
      />
    </div>
  );
}

export default EventPage;
