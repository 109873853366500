export default function SearchIcon({
  size = 24,
  color = "url(#paint0_linear_1540_3983)",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.707 22.2928L17.738 16.3238C19.3646 14.3344 20.1643 11.7959 19.9718 9.23334C19.7793 6.67081 18.6092 4.28031 16.7036 2.5563C14.7979 0.832293 12.3026 -0.0933258 9.73361 -0.029094C7.16466 0.0351379 4.71867 1.08431 2.90158 2.9014C1.08449 4.71849 0.035321 7.16447 -0.0289109 9.73342C-0.0931427 12.3024 0.832476 14.7977 2.55649 16.7034C4.2805 18.609 6.671 19.7791 9.23353 19.9716C11.7961 20.1642 14.3346 19.3644 16.324 17.7378L22.293 23.7068C22.4816 23.8889 22.7342 23.9897 22.9964 23.9875C23.2586 23.9852 23.5094 23.88 23.6948 23.6946C23.8802 23.5092 23.9854 23.2584 23.9876 22.9962C23.9899 22.734 23.8891 22.4814 23.707 22.2928ZM9.99997 17.9998C8.41772 17.9998 6.871 17.5306 5.5554 16.6515C4.23981 15.7725 3.21443 14.5231 2.60893 13.0613C2.00343 11.5994 1.845 9.99091 2.15368 8.43906C2.46236 6.88721 3.22429 5.46175 4.34311 4.34293C5.46193 3.22411 6.88739 2.46218 8.43924 2.1535C9.99109 1.84482 11.5996 2.00324 13.0614 2.60875C14.5232 3.21425 15.7727 4.23963 16.6517 5.55522C17.5308 6.87081 18 8.41753 18 9.99978C17.9976 12.1208 17.154 14.1542 15.6542 15.654C14.1544 17.1538 12.121 17.9974 9.99997 17.9998Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1540_3983"
          x1="-0.0320435"
          y1="11.9776"
          x2="23.9877"
          y2="11.9776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB3318" />
          <stop offset="1" stopColor="#FF8A4A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
