const colors = {
  light: {
    white: "#ffffff",
    primary1: {
      main: "#EB3318",
    },
    primary2: {
      main: "#FF8A4A",
      tint5: "#FFECE1",
    },
    secondary1: {
      main: "#006C8D",
    },
    secondary2: {
      main: "#40E0D0",
    },
    marking: {
      red: "#D94029",
      green: "#67B36A",
    },
    gray: {
      main: "#808080",
      gray1: "#ababab",
      gray2: "#cccccc",
      dark: "#333333",
    },
    black: {
      main: "#000000",
    },
  },
  dark: {},
};

export default colors;
