import apiClient from "./client";

const endpoint = "/auth";

const authApi = {
  login: ({ email, password }) =>
    apiClient.post(
      `${endpoint}/local/login`,
      { email, password },
      { headers: { lng: "hu" } }
    ),
};

export default authApi;
