import { useState } from "react";
import usersApi from "../api/users";
import useApi from "./useApi";

function useUser({ id }) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const { request: getUser } = useApi({
    call: usersApi.getUser,
    initParams: id ? { id } : null,
    init: [initLoading, setInitLoading],
    loading: [loading, setLoading],
    data: [user, setUser],
  });

  return { initLoading, loading, user, getUser };
}

export default useUser;
