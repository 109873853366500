import React, { createRef, forwardRef, useState } from 'react';
import { ArrowSmallDown } from '../icons';
import SPText from '../SPText/SPText';
import styles from './SPSelect.module.css';

/**
 * SPSelect
 * @param {array} values is an array of strings, that will be used as
 * values and names for the dropdown items
 * @param {string} current is the currently selected value of the
 * select component
 * @param {function} setCurrent is a callback function to
 * set the current value outside the component into a state for example
 * @param {*} ref reference to the select
 * @returns {React.Element} SPSelect
 */

function SPSelect({ values, current, setCurrent }, ref) {
  const inputRef = ref || createRef(null);
  const [selected, setSelected] = useState(current || values[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className={styles.container}>
      <select
        ref={inputRef}
        value={selected}
        onChange={e => {
          setSelected(e.target.value)
          setCurrent(e.target.value)
        }}
      >
        {values && values.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
      <div
        className={styles.header}
        onClick={() => setShowDropdown(true)}
        style={{cursor: 'pointer'}}
      >
        <SPText variant='name-tag'>
          {selected}
        </SPText>
        <ArrowSmallDown
          size={14}
          cursor="pointer"
        />
      </div>
      {showDropdown && (
        <div className={styles.dropdown} onMouseLeave={() => setShowDropdown(false)}>
          {values && values.map(value => (
            <span
              key={value}
              style={{
                cursor: 'pointer',
                fontWeight: selected === value ? 800 : 500,
              }}>
              <SPText
                variant='name-tag'
                marginBottom={6}
                onClick={() => {
                  setSelected(value);
                  setShowDropdown(false);
                  setCurrent(value)
                }}>
                {value}
              </SPText>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default forwardRef(SPSelect);