export default function ArrowSmallLeft({
  size = 24,
  color = "#FFF",
  ...props
}) {
  return (
    <>
      {color === "gradient" ? (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 4.99982H3L6.29 1.70982C6.38373 1.61685 6.45812 1.50625 6.50889 1.38439C6.55966 1.26253 6.5858 1.13183 6.5858 0.999816C6.5858 0.867804 6.55966 0.737098 6.50889 0.615239C6.45812 0.49338 6.38373 0.382779 6.29 0.289816C6.10264 0.103565 5.84919 -0.000976562 5.585 -0.000976562C5.32081 -0.000976562 5.06736 0.103565 4.88 0.289816L0.59 4.58982C0.214412 4.96317 0.00223279 5.47024 0 5.99982C0.00486659 6.52592 0.216844 7.02892 0.59 7.39982L4.88 11.6998C4.97324 11.7924 5.0838 11.8657 5.20537 11.9156C5.32694 11.9654 5.45714 11.9908 5.58854 11.9904C5.71993 11.9899 5.84995 11.9636 5.97116 11.9129C6.09238 11.8621 6.20242 11.7881 6.295 11.6948C6.38758 11.6016 6.46089 11.491 6.51075 11.3694C6.5606 11.2479 6.58602 11.1177 6.58556 10.9863C6.58509 10.8549 6.55875 10.7249 6.50804 10.6037C6.45733 10.4824 6.38324 10.3724 6.29 10.2798L3 6.99982H13C13.2652 6.99982 13.5196 6.89446 13.7071 6.70692C13.8946 6.51939 14 6.26503 14 5.99982C14 5.7346 13.8946 5.48025 13.7071 5.29271C13.5196 5.10517 13.2652 4.99982 13 4.99982Z"
            fill="url(#paint0_linear_1742_1449)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1742_1449"
              x1={0.833332}
              y1={7.99326}
              x2={19.5}
              y2={7.99326}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EB3318" />
              <stop offset={1} stopColor="#FF8A4A" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 4.99982H3L6.29 1.70982C6.38373 1.61685 6.45812 1.50625 6.50889 1.38439C6.55966 1.26253 6.5858 1.13183 6.5858 0.999816C6.5858 0.867804 6.55966 0.737098 6.50889 0.615239C6.45812 0.49338 6.38373 0.382779 6.29 0.289816C6.10264 0.103565 5.84919 -0.000976562 5.585 -0.000976562C5.32081 -0.000976562 5.06736 0.103565 4.88 0.289816L0.59 4.58982C0.214412 4.96317 0.00223279 5.47024 0 5.99982C0.00486659 6.52592 0.216844 7.02892 0.59 7.39982L4.88 11.6998C4.97324 11.7924 5.0838 11.8657 5.20537 11.9156C5.32694 11.9654 5.45714 11.9908 5.58854 11.9904C5.71993 11.9899 5.84995 11.9636 5.97116 11.9129C6.09238 11.8621 6.20242 11.7881 6.295 11.6948C6.38758 11.6016 6.46089 11.491 6.51075 11.3694C6.5606 11.2479 6.58602 11.1177 6.58556 10.9863C6.58509 10.8549 6.55875 10.7249 6.50804 10.6037C6.45733 10.4824 6.38324 10.3724 6.29 10.2798L3 6.99982H13C13.2652 6.99982 13.5196 6.89446 13.7071 6.70692C13.8946 6.51939 14 6.26503 14 5.99982C14 5.7346 13.8946 5.48025 13.7071 5.29271C13.5196 5.10517 13.2652 4.99982 13 4.99982Z"
            fill={color}
          />
        </svg>
      )}
    </>
  );
}
