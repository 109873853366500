import React, { useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Header,
  SPButton,
  SPText,
  SPModal,
  LabeledContainer,
  ImageFormCard,
} from "../../components";
import { PencilIcon, TrashIcon } from "../../components/icons";
import useSport from "../../hooks/useSport";
import styles from "./SportPage.module.css";
import colors from "../../config/colors";
import useToast from "../../hooks/useToast";

function SportPage() {
  const { deleteError, deleteSuccess } = useToast();
  const { sportId } = useParams();
  const { sport, deleteSport, loading } = useSport(sportId);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const deleteSportHandler = async () => {
    const res = await deleteSport(sportId);
    if (res.ok) {
      deleteSuccess();
      setShowModal(false);
      navigate("/sports");
    } else {
      deleteError();
    }
  };

  return (
    <>
      <SPModal visible={showModal} onCancel={() => setShowModal(false)}>
        <SPText variant="h1" textAlign="center">
          Sport törlése
        </SPText>
        <SPText>
          Miután törölted, a felhasználók adatlapjáról is törlésre kerül ez a
          sport.
        </SPText>
        <SPButton
          disabled={loading}
          title="törlés"
          onClick={deleteSportHandler}
        />
      </SPModal>

      <div className={styles.container}>
        <Header
          title={{ plain: "Sport", highlighted: "megtekintése" }}
          withBack
          backNavPath="/sports"
          backNavOptions={{ state: location?.state }}
        />
        {loading && <div>loading...</div>}
        {!loading && sport && (
          <div className={styles.rowsContainer}>
            <div className={`${styles.detailsRow} ${styles.spaceBetween}`}>
              <div className={styles.leftContainer}>
                <LabeledContainer label="Sport ID" text={sport.id} />
                <LabeledContainer
                  label="Státusz"
                  text={sport.status}
                  textProps={{
                    style: { textTransform: "uppercase" },
                    color:
                      sport.status === "approved"
                        ? colors.light.marking.green
                        : colors.light.marking.red,
                  }}
                />
                <LabeledContainer
                  label="Módosítás dátuma"
                  text={dayjs(sport.createdAt).format("YYYY.MM.DD.")}
                />
              </div>
              <div className={styles.rightContainer}>
                <SPButton
                  title="Módosítás"
                  Icon={PencilIcon}
                  IconProps={{ size: 24 }}
                  onClick={() => navigate(`/sports/${sport.id}/edit`)}
                />
                <SPButton
                  variant="secondary"
                  shape="icon"
                  Icon={TrashIcon}
                  IconProps={{ size: 24 }}
                  className={styles.removeButton}
                  onClick={() => setShowModal(true)}
                />
              </div>
            </div>
            <div className={styles.detailsRow}>
              <LabeledContainer label="Ikon">
                <div
                  className={styles.sportIcon}
                  style={{
                    background: `url(${sport.icon})`,
                    backgroundSize: "cover",
                  }}
                />
              </LabeledContainer>
              <LabeledContainer label="Angol neve" text={sport.name.en} />
              <LabeledContainer label="Magyar neve" text={sport.name.hu} />
            </div>
            <LabeledContainer label="Borítókép eseményekhez">
              <div className={styles.covers}>
                {sport.coverImages.data?.length === 0 && <ImageFormCard />}
                {sport.coverImages.data?.length > 0 &&
                  Array.from(sport.coverImages.data).map((coverObj) => (
                    <ImageFormCard
                      key={coverObj.id.toString()}
                      imageUrl={coverObj.url}
                      removeBtn={false}
                    />
                  ))}
              </div>
            </LabeledContainer>
          </div>
        )}
      </div>
    </>
  );
}

export default SportPage;
