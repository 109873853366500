import PropTypes from "prop-types";
import React, { useState } from "react";

import colors from "../../config/colors";
import { ArrowSmallLeft, ArrowSmallRight } from "../icons";
import SPText from "../SPText/SPText";
import styles from "./PageSelector.module.css";

export default function PageSelector({ page, maxPage, onChange, className }) {
  const getTileNumbers = (currentPage) => {
    let tileNumbers = [];
    if (currentPage === maxPage) {
      tileNumbers = [maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 3) {
      tileNumbers = Array.from(
        { length: Math.min(maxPage, 3) },
        (_, i) => i + 1
      );
    } else if (currentPage > 1 && currentPage < maxPage) {
      tileNumbers = [currentPage - 1, currentPage, currentPage + 1];
    }
    return tileNumbers.filter((number) => number > 0);
  };

  const [tileNumbers, setTileNumbers] = useState(getTileNumbers(page));

  const handlePageChange = (toPage) => {
    if (toPage < 1 || toPage > maxPage || toPage === page) return;
    setTileNumbers(getTileNumbers(toPage));
    return onChange(toPage - 1);
  };

  const Tile = ({ tileNumber, active }) => (
    <div
      className={`${styles.tile} ${active && styles.activeTile}`}
      onClick={() => handlePageChange(tileNumber)}
    >
      <SPText
        variant="button-name"
        color={active ? colors.light.gray.dark : colors.light.gray.gray2}
      >
        {tileNumber}
      </SPText>
    </div>
  );

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.arrow} onClick={() => handlePageChange(page - 1)}>
        <ArrowSmallLeft
          color={page === 1 ? colors.light.gray.gray1 : "gradient"}
        />
      </div>

      {tileNumbers.map((number) => (
        <Tile
          key={number.toString()}
          tileNumber={number}
          active={number === page}
        />
      ))}
      <div className={styles.arrow} onClick={() => handlePageChange(page + 1)}>
        <ArrowSmallRight
          color={
            page === maxPage || maxPage === 0
              ? colors.light.gray.gray1
              : "gradient"
          }
        />
      </div>
    </div>
  );
}

PageSelector.propTypes = {
  page: PropTypes.number,
  maxPage: PropTypes.number,
  onChange: PropTypes.func,
};
