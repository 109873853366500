import React, { useState } from "react";
import useLogs from "../../hooks/useLogs";
import { ArrowSmallRight } from "../../components/icons";
import {
  SPButton,
  Header,
  SPTable,
  Badge,
  SPText,
  SPDatePicker,
  SPModal,
} from "../../components";
import { Log, badgeColors } from "../../components/pages/LogsPage";
import useQuery from "../../hooks/useQuery";
import styles from "./LogsPage.module.css";

function LogsPage() {
  const { loading, logs, meta, getLogs } = useLogs();
  const [selectedLog, setSelectedLog] = useState(null);
  const [filter, setFilter] = useState({
    createdAt: "",
  });
  const prepareFilterData = (data) => ({
    createdAt: data.createdAt || null,
  });
  const { queryChanging } = useQuery({
    query: filter,
    timeout: 500,
    request: ({ nextQuery }) =>
      getLogs({ page: 0, filterData: prepareFilterData(nextQuery) }),
  });
  const Action = ({ item }) => (
    <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        onClick={() => setSelectedLog(item)}
      />
    </div>
  );
  const columns = [
    {
      title: "Azonosító",
      field: "id",
    },
    {
      title: "Dátum",
      field: "createdAt",
      Component: ({ column }) => {
        const date = new Date(column).toLocaleDateString("hu-HU");
        return <SPText variant="selection-text">{date}</SPText>;
      },
    },
    {
      title: "Típus",
      field: ["variant", "type"],
      Component: ({ column }) => {
        const [variant, type] = column;
        return (
          <Badge
            variant="long"
            title={type}
            color={badgeColors[variant]}
            textColor={badgeColors[variant]}
            outlined
          />
        );
      },
    },
  ];
  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Logok",
          highlighted: "listája",
        }}
      />
      <SPModal
        visible={selectedLog}
        onCancel={() => setSelectedLog(null)}
        onCancelTitle="Vissza"
      >
        {selectedLog && <Log log={selectedLog} />}
      </SPModal>
      <div className={styles["search-container"]}>
        <SPDatePicker
          loading={queryChanging}
          value={filter.createdAt}
          onChange={(createdAt) => setFilter({ ...filter, createdAt })}
        />
      </div>
      {loading && <div>Loading...</div>}
      {logs && (
        <SPTable
          items={logs}
          meta={meta}
          onPageChange={(page) => getLogs({ page })}
          columns={columns}
          Action={Action}
        />
      )}
    </div>
  );
}

export default LogsPage;
