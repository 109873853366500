import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  SPButton,
  SPCheckbox,
  SPInput,
  SPModal,
  SPTable,
  SPText,
} from "../../components";
import {
  ArrowSmallRight,
  PlusIcon,
  SearchIcon,
  TrashIcon,
} from "../../components/icons";
import useQuery from "../../hooks/useQuery";
import useSport from "../../hooks/useSport";
import useSports from "../../hooks/useSports";
import useToast from "../../hooks/useToast";
import styles from "./SportsPage.module.css";

const prepareFilterData = (data) => {
  const statuses = [];
  if (data.registered) statuses.push("registered");
  if (data.approved) statuses.push("approved");
  return {
    status: statuses.length > 0 ? statuses.join(",") : null,
    s: data.sport || null,
  };
};

function SportsPage() {
  const { deleteError, deleteSuccess } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState({
    registered: location?.state?.registered || false,
    approved: location?.state?.approved || false,
    sport: location?.state?.sport || "",
  });
  const { loading, sports, meta, getSports } = useSports(
    prepareFilterData(filter),
    location?.state?.page || 0
  );
  const { deleteSport, loading: deleteLoading } = useSport();
  const [showModal, setShowModal] = useState(false);
  const [selectedSport, setSelectedSport] = useState(null);

  const { queryChanging } = useQuery({
    query: filter,
    timeout: 500,
    request: ({ nextQuery }) =>
      sports &&
      getSports({
        page: 0,
        filterData: prepareFilterData(nextQuery),
      }),
  });

  const deleteSportHandler = async () => {
    const res = await deleteSport(selectedSport);
    if (res.ok) {
      deleteSuccess();
      await getSports({
        page: meta.page,
        filterData: prepareFilterData(filter),
      });
    } else {
      deleteError();
    }
    setShowModal(false);
  };

  const StatusItem = ({ status }) => (
    <SPText
      variant="name-tag"
      color={status === "approved" ? "#67B36A" : "#D94029"}
    >
      {status}
    </SPText>
  );

  const Action = ({ item, ...props }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "16px",
      }}
      {...props}
    >
      <SPButton
        variant="secondary"
        shape="icon"
        Icon={TrashIcon}
        IconProps={{ size: 22 }}
        onClick={() => {
          setSelectedSport(item.id);
          setShowModal(true);
        }}
      />
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        IconProps={{ size: 12 }}
        onClick={() =>
          navigate(`/sports/${item.id}`, {
            state: { ...filter, page: meta.page },
          })
        }
      />
    </div>
  );

  const columns = [
    { title: "Azonosító", field: "id" },
    { title: "Angol név", field: "name.en" },
    { title: "Magyar név", field: "name.hu" },
    {
      title: "Státusz",
      field: "status",
      Component: ({ column }) => <StatusItem status={column} />,
    },
    { title: "Ajánlott név", field: "suggestedName" },
  ];

  function NewSportButton() {
    return (
      <SPButton
        title="Sport hozzáadása"
        onClick={() => navigate("/sports/new/edit")}
        Icon={PlusIcon}
        IconProps={{ size: 17 }}
      />
    );
  }

  return (
    <>
      <SPModal
        visible={showModal}
        onCancel={() => {
          setSelectedSport(null);
          setShowModal(false);
        }}
      >
        <SPText variant="h1" textAlign="center">
          Sport törlése
        </SPText>
        <SPText>
          Miután törölted, a felhasználók adatlapjáról is törlésre kerül ez a
          sport.
        </SPText>
        <SPButton
          disabled={deleteLoading}
          title="törlés"
          onClick={deleteSportHandler}
        />
      </SPModal>
      <div className={styles.container}>
        <div>
          <Header
            title={{ plain: "Sportok", highlighted: "listája" }}
            Action={<NewSportButton />}
          />
          <form
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 24,
            }}
          >
            <div style={{ display: "flex" }}>
              <SPCheckbox
                value={filter.registered}
                onChange={(registered) => setFilter({ ...filter, registered })}
                label="Registered"
              />
              <div style={{ width: 24 }}></div>
              <SPCheckbox
                value={filter.approved}
                onChange={(approved) => setFilter({ ...filter, approved })}
                label="Approved"
              />
            </div>
            <SPInput
              value={filter.sport}
              onChange={(e) => setFilter({ ...filter, sport: e.target.value })}
              placeholder="Sport neve..."
              PrimaryIcon={!queryChanging && SearchIcon}
            />
          </form>
          {!loading && sports.length > 0 && (
            <SPTable
              meta={meta}
              items={sports}
              columns={columns}
              Action={Action}
              onPageChange={(page) =>
                getSports({ page, filterData: prepareFilterData(filter) })
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SportsPage;
