import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { PictureIcon, UserOutlinedIcon } from "../../components/icons";
import {
  Header,
  SPButton,
  ImageFormCard,
  SPInput,
  SPRadioButtons,
  SPText,
  SPTextArea,
  QuerySelect,
} from "../../components";
import SPInputError from "../../components/SPInput/SPInputError";
import useClub from "../../hooks/useClub";
import styles from "./EditClubPage.module.css";
import sportsApi from "../../api/sports";
import usersAPi from "../../api/users";

const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpeg"];

function EditClubPage() {
  const { clubId } = useParams();
  const { club, loading, upsertClub, initLoading } = useClub(clubId);
  const navigate = useNavigate();

  const yupSchema = Yup.object().shape({
    name: Yup.string()
      .required("Név Kötelező!")
      .min(2, "Névnek minimum 2 karakterből kell állnia!"),
    type: Yup.string().required("Típus Kötelező!"),
    description: Yup.string()
      .required("Leírás Kötelező!")
      .min(2, "Leírásnak minimum 2 karakterből kell állnia!"),
    admin: Yup.object()
      .default(null)
      .nullable()
      .required("Adminisztrátor Kötelező!"),
    sports: Yup.array()
      .required("Sport Kötelező!")
      .min(1, "Legalább egy sport Kötelező!"),
    covers: Yup.array()
      .required("Borítókép Kötelező!")
      .length(1, "Borítókép Kötelező!"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      type: "public",
      description: "",
      // adding a default admin id
      admin: null,
      sports: [],
      covers: [],
    },
    resolver: yupResolver(yupSchema),
  });

  console.log(errors);

  const getUrl = (value) => {
    if (typeof value !== "string") {
      return URL.createObjectURL(value);
    }
    return value;
  };

  const onSubmitHandler = async ({
    name,
    type,
    description,
    admin,
    sports,
    covers,
  }) => {
    await upsertClub({
      clubId,
      name,
      type,
      description,
      admin: admin?.id,
      sports: sports.map((s) => s.id),
      cover: covers.length > 0 ? covers[0] : null,
      cb: (res) =>
        clubId === "new"
          ? navigate(`/clubs/${res.data.id}`, { replace: true })
          : navigate(-1),
    });
  };

  useEffect(() => {
    if (club) {
      setValue("name", club.name);
      setValue("type", club.type);
      setValue("maxMembers", club.maxMembers);
      setValue("description", club.description);
      setValue("admin", club.admin);
      setValue("sports", club.sports.data);
      setValue("covers", [club.cover]);
    }
  }, [club, setValue]);

  if (initLoading) return <div></div>;

  return (
    <div className={styles.container}>
      <Header
        title={{
          plain: "Klub",
          highlighted: clubId === "new" ? "létrehozása" : "módosítása",
        }}
        withBack
        withModal
      />
      {(loading || control === undefined) && <div>loading...</div>}
      {!loading && (
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className={styles.column}
        >
          <div className={styles.row}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <SPInput
                  {...field}
                  type="text"
                  placeholder="Szöveg"
                  label="Klub neve"
                  error={errors?.name?.message}
                />
              )}
            />
            <Controller
              name="admin"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <QuerySelect
                    request={usersAPi.getUsers}
                    labelField={"id"}
                    keyField={"id"}
                    value={value}
                    onChange={(v) => {
                      console.log(v);
                      onChange(v);
                    }}
                    PrimaryIcon={UserOutlinedIcon}
                    primaryIconProps={{ color: "#FF8A4A" }}
                    label="Klub adminisztrátora"
                    placeholder="Felhasználó ID..."
                    singleSelect={true}
                  />
                  <SPInputError error={errors?.admin?.message} />
                </div>
              )}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="sports"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <QuerySelect
                    request={sportsApi.getSports}
                    labelField={"name.hu"}
                    fallbackLabelField={"name"}
                    keyField={"id"}
                    value={value}
                    onChange={onChange}
                    label="Klub sportjai"
                    placeholder="Sport neve..."
                    initFilterData={{ status: "approved" }}
                  />
                  <SPInputError error={errors?.sports?.message} />
                </div>
              )}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <div className={styles.descriptionContainer}>
                  <SPTextArea
                    {...field}
                    placeholder="Szöveg"
                    label="Klub leírása"
                  />
                  <SPInputError error={errors?.description?.message} />
                </div>
              )}
            />
          </div>
          <div className={styles.row} id="covers">
            <Controller
              name="covers"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <SPInput
                    onChange={(e) => {
                      if (!ALLOWED_IMAGE_TYPES.includes(e.target.files[0].type))
                        return setError("covers", {
                          type: "custom",
                          message:
                            "Csak .png és jpg fájlokat lehet feltölteni!",
                        });
                      onChange([e.target.files[0]]);
                      setError("covers", null);
                    }}
                    type="file"
                    label="Borítókép"
                    PrimaryIcon={PictureIcon}
                    className={styles.fileUpload}
                    error={errors?.covers?.message}
                    accept={ALLOWED_IMAGE_TYPES}
                  />
                  <div className={styles.cover}>
                    {value?.length === 0 && <ImageFormCard />}
                    {value?.length > 0 &&
                      Array.from(value).map((imageObj, index) => (
                        <ImageFormCard
                          key={index.toString()}
                          imageUrl={getUrl(imageObj)}
                          removeBtn={false}
                          //   onRemove={() => {
                          //     onChange(
                          //       value.filter(
                          //         (image, filterIndex) => index !== filterIndex
                          //       )
                          //     );
                          //     if (typeof imageObj === "string") {
                          //       handleDeleteCover(imageObj);
                          //     }
                          //   }}
                        />
                      ))}
                  </div>
                </>
              )}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <div className={styles.column}>
                  <SPText variant="h3" marginBottom={14}>
                    Klub típusa
                  </SPText>
                  <SPRadioButtons
                    {...field}
                    options={[
                      { label: "Publikus", value: "public" },
                      { label: "Publikus/Zárt", value: "public-closed" },
                      { label: "Zárt", value: "closed" },
                    ]}
                    gap={40}
                  />
                  <SPInputError error={errors?.type?.message} />
                </div>
              )}
            />
          </div>
          <SPButton type="submit" title="Mentés" className={styles.button} />
        </form>
      )}
    </div>
  );
}

export default EditClubPage;
