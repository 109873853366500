import React from "react";
import PropTypes from "prop-types";
import styles from "./SPButton.module.css";
import SPText from "../SPText/SPText";

/**
 * ### SPButton
 * **Splinker** button component.
 * @component
 * @prop {string} title Title of the button.
 * @prop {string} variant `'primary' | 'secondary' | 'plain'`
 * @prop {string} shape `'button' | 'icon'`
 * @prop {React.component} Icon Component reference of the icon.
 * @prop {object} IconProps Icon properties applied to the `Icon`.
 * @prop {boolean} disabled `true | false`
 * @prop {Function} onClick Action when the button is pressed.
 * @prop {number} marginBottom Spacing in px below the button.
 * @prop {string} type button type: `'button'|'submit'`.
 * @prop {string} className custom class for the button container
 * @returns {React.ReactElement} SPButton
 * @example
 * import {ArrowSmallLeft} from "./components/icons";
 *
 * return (
 *  <SPButton
 *    title={'Click me'}
 *    variant={'primary'}
 *    shape={'button'}
 *    Icon={CheckIcon}
 *    IconProps={{size: 24}}
 *    disabled={false}
 *    onPress={()=>console.log('button pressed')}
 *    marginBottom={12}
 *  />
 * )
 */

function SPButton({
  title = null,
  variant = "primary",
  shape = "button",
  Icon = null,
  IconProps = {},
  disabled = false,
  onClick,
  marginBottom,
  type = "button",
  className = "",
}) {
  const textColor =
    variant === "primary" || variant === "plain"
      ? disabled
        ? "#ABABAB"
        : "#FFFFFF"
      : disabled
      ? "#CCCCCC"
      : "#333333";
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${
        shape === "icon" ? styles.circle : ""
      } ${className}`}
      style={{ marginBottom }}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <SPText variant="button-name" color={textColor}>
        {title}
      </SPText>
      {Icon && (
        <Icon
          style={{ marginLeft: title ? "12px" : "" }}
          color={textColor}
          size={14}
          {...IconProps}
        />
      )}
    </button>
  );
}

SPButton.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  shape: PropTypes.string,
  Icon: PropTypes.elementType,
  IconProps: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  marginBottom: PropTypes.number,
  type: PropTypes.oneOf(["button", "submit"]),
};

export default SPButton;
