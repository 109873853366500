import React from "react";
import styles from "./SPText.module.css";

/**
 * ### SPText
 * **Splinker** text component. Every text is wrapped by `<SPText>...</SPText>` and the `variant` and `color` props are set according to the design.
 * @component
 * @prop {React.ReactElement} children Child component.
 * @prop {string} variant `'h1'|
    'h2'|
    'h3'|
    'selection-text'|
    'comment-text'|
    'comment-text-bold'|
    'event-club'|
    'name-tag'|
    'menu-name'|
    'button-name'`
 * @prop {string} color Color of the text.
 * @prop {string} textAlign `'left'|'right'|'center'`
 * @prop {number} marginBottom Spacing in px below the text.
 * @prop {object} style React styling object.
 * @returns {React.ReactElement} SPText
 * @example
 * return (
 *  <SPText
 *    color={"#FFFFFF"}
 *    textAlign={'center'}
 *    style={{textDecoration: 'uppercase'}}
 *  >
 *   ...
 *  </SPText>
 * )
*/

export default function SPText({
  children,
  variant,
  color,
  textAlign,
  style,
  marginBottom,
  className,
  ...props
}) {
  return (
    <div
      className={`${styles.text} ${styles[variant]} ${className}`}
      style={{ color, textAlign, marginBottom, ...style }}
      {...props}
    >
      {children}
    </div>
  );
}
