import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "../components";
import { ClubPage, ClubsPage, EditClubPage } from "../pages/clubs";
import { DashboardPage } from "../pages/dashboard";
import { DocsPage, UploadDocPage } from "../pages/docs";
import { EventPage, EventsPage } from "../pages/events";
import { LogsPage } from "../pages/logs";
import { PostPage, PostsPage } from "../pages/posts";
import { SportPage, SportsPage, EditSportPage } from "../pages/sports";
import { UserPage, UsersPage } from "../pages/users";

function AppRoutes() {
  return (
    <Layout>
      <Routes>
        {/* Navigate "/" to "/dashboard" */}
        <Route path="/" element={<Navigate to="/dashboard" />} replace />
        {/* Dashboard routes */}
        <Route path="/dashboard" element={<DashboardPage />} />

        {/* Users routes */}
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:userId" element={<UserPage />} />

        {/* Posts routes */}
        <Route path="/posts" element={<PostsPage />} />
        <Route path="/posts/:postId" element={<PostPage />} />

        {/* Sports routes */}
        <Route path="/sports" element={<SportsPage />} />
        <Route path="/sports/:sportId" element={<SportPage />} />
        <Route path="/sports/:sportId/edit" element={<EditSportPage />} />

        {/* Events routes */}
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events/:eventId" element={<EventPage />} />

        {/* Clubs routes */}
        <Route path="/clubs" element={<ClubsPage />} />
        <Route path="/clubs/:clubId" element={<ClubPage />} />
        <Route path="/clubs/:clubId/edit" element={<EditClubPage />} />

        {/* Logs routes */}
        <Route path="/logs" element={<LogsPage />} />

        {/* Docs routes */}
        <Route path="/docs" element={<DocsPage />} />
        <Route path="/docs/upload-doc" element={<UploadDocPage />} />

        {/* "No Match Route" */}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Layout>
  );
}

export default AppRoutes;
