export default function EyeIcon({ size = 24, color = "#CCCCCC", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1572_61)">
        <path
          d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655C5.80801 2.655 2.28001 6.893 0.729005 9.419C0.249533 10.1945 -0.00444031 11.0882 -0.00444031 12C-0.00444031 12.9118 0.249533 13.8055 0.729005 14.581C2.28001 17.107 5.80801 21.345 12 21.345C18.192 21.345 21.72 17.107 23.271 14.581C23.7505 13.8055 24.0045 12.9118 24.0045 12C24.0045 11.0882 23.7505 10.1945 23.271 9.419ZM21.566 13.534C20.234 15.7 17.219 19.345 12 19.345C6.78101 19.345 3.76601 15.7 2.43401 13.534C2.14914 13.073 1.99826 12.5419 1.99826 12C1.99826 11.4581 2.14914 10.927 2.43401 10.466C3.76601 8.3 6.78101 4.655 12 4.655C17.219 4.655 20.234 8.296 21.566 10.466C21.8509 10.927 22.0018 11.4581 22.0018 12C22.0018 12.5419 21.8509 13.073 21.566 13.534Z"
          fill={color}
        />
        <path
          d="M12 7C11.0111 7 10.0444 7.29324 9.22215 7.84265C8.3999 8.39206 7.75904 9.17295 7.3806 10.0866C7.00217 11.0002 6.90315 12.0055 7.09608 12.9755C7.289 13.9454 7.76521 14.8363 8.46447 15.5355C9.16373 16.2348 10.0546 16.711 11.0245 16.9039C11.9945 17.0969 12.9998 16.9978 13.9134 16.6194C14.827 16.241 15.6079 15.6001 16.1573 14.7779C16.7068 13.9556 17 12.9889 17 12C16.9984 10.6744 16.4711 9.40356 15.5338 8.46622C14.5964 7.52888 13.3256 7.00159 12 7ZM12 15C11.4067 15 10.8266 14.8241 10.3333 14.4944C9.83994 14.1648 9.45543 13.6962 9.22836 13.148C9.0013 12.5999 8.94189 11.9967 9.05765 11.4147C9.1734 10.8328 9.45912 10.2982 9.87868 9.87868C10.2982 9.45912 10.8328 9.1734 11.4147 9.05764C11.9967 8.94189 12.5999 9.0013 13.1481 9.22836C13.6962 9.45542 14.1648 9.83994 14.4944 10.3333C14.8241 10.8266 15 11.4067 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7957 15 12 15Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1572_61">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
