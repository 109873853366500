import client, { requestConfig } from "./client";

const endpoint = "/club-posts";

const getPosts = ({ page = 0, pageSize = 20, filterData = {} }) =>
  client.get(
    endpoint,
    {
      as: "admin",
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );

const getPost = (id) =>
  client.get(
    `${endpoint}/${id}`,
    { as: "admin" },
    { headers: { ...requestConfig().headers } }
  );

const deletePost = (id) =>
  client.delete(
    `${endpoint}/${id}`,
    { as: "admin" },
    { headers: { ...requestConfig().headers } }
  );

export default {
  getPosts,
  getPost,
  deletePost,
};
