import React, {
  useState,
  createRef,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

import { CalendarIcon } from "../icons";
import SPText from "../SPText/SPText";
import SPSelect from "../SPSelect/SPSelect";
import styles from "./SPDatePicker.module.css";

dayjs.extend(updateLocale);

const range = (start, end) =>
  Array.apply(0, Array(end - start + 1)).map((_, index) => index + start);

const years = range(2010, new Date(Date.now()).getFullYear()).reverse();

const months = {
  0: "Január",
  1: "Február",
  2: "Március",
  3: "Április",
  4: "Május",
  5: "Június",
  6: "Július",
  7: "Augusztus",
  8: "Szeptember",
  9: "Október",
  10: "November",
  11: "December",
};

dayjs.updateLocale("en", {
  months: Object.values(months),
});

const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"];

const getMonthNumber = (value) => {
  let number = Object.keys(months).find((key) => months[key] === value);
  number = parseInt(number, 10) + 1;
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

const getDateNumber = (value) => {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
};

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function getWeeks(month, year) {
  const weeks = [];
  const days = range(1, daysInMonth(month, year));
  let week = [];
  days.forEach((day) => {
    const number = new Date(year, month - 1, day).getDay();
    week.push(day);
    if (number === 0) {
      weeks.push(week);
      week = [];
    }
  });
  if (week.length > 0) weeks.push(week);
  if (weeks[0].length < 7) {
    weeks[0] = [...Array(7 - weeks[0].length).fill(null), ...weeks[0]];
  }
  return weeks;
}

const current = new Date();

/**
 * SPDatePicker
 * @param {array} marginBottom is the distance of the component
 * from the component bellow
 * @returns {React.Element} SPDatePicker
 */

function SPDatePicker({ onChange, loading, marginBottom }, ref) {
  const inputRef = ref || createRef(null);
  const modalRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [date, setDate] = useState({
    year: current.getFullYear(),
    month: months[current.getMonth()],
    day: current.getDate() < 10 ? `0${current.getDate()}` : current.getDate(),
  });
  const [weeks, setWeeks] = useState(null);
  useEffect(() => {
    setWeeks(getWeeks(getMonthNumber(date.month), date.year));
  }, [date.month, date.year]);
  useEffect(() => {
    const { year, month, day } = date;
    const newValue = `${year}-${getMonthNumber(month)}-${day}`;
    inputRef.current.value = newValue;
    onChange(newValue);
  }, [date]);
  useEffect(() => {
    inputRef.current.value = "";
    onChange("");
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        inputRef.current &&
        !modalRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setDatePickerOpen(false);
        setFocus(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [modalRef, inputRef]);

  return (
    <div className={styles.content}>
      <div
        className={`${styles.container} ${focus ? styles.focus : ""}`}
        style={{ marginBottom }}
        onClick={(e) => {
          e.preventDefault();
          if (!datePickerOpen) {
            setFocus(true);
            setDatePickerOpen(!datePickerOpen);
            return;
          }
          setFocus(false);
          setDatePickerOpen(false);
        }}
      >
        <input
          className={styles.input}
          type="text"
          ref={inputRef}
          placeholder="Dátum"
          onKeyDown={(e) => {
            console.log("key down", e.code);
            if (e.code !== "Enter") return;
            console.log("value", e.target.value);
            if (!e.target.value) return onChange("");
            try {
              const newDate = dayjs(e.target.value);
              if (newDate < dayjs("2010-01-01")) return;
              console.log({
                year: newDate.year(),
                month: newDate.format("MMMM"),
                day: newDate.format("DD"),
              });
              if (Number.isNaN(newDate.year())) return;
              setDate({
                year: newDate.year(),
                month: newDate.format("MMMM"),
                day: newDate.format("DD"),
              });
            } catch (err) {
              console.log(err);
            }
          }}
        />
        <div className={styles.icons}>
          <div className={styles.icon}>{!loading && <CalendarIcon />}</div>
        </div>
      </div>

      {datePickerOpen && (
        <div
          ref={modalRef}
          className={styles["date-picker"]}
          onClick={(e) => {
            console.log(e);
          }}
        >
          <div className={styles["date-picker-header"]}>
            <SPSelect
              values={years}
              current={date.year}
              setCurrent={(newValue) => setDate({ ...date, year: newValue })}
            />
            <SPSelect
              values={Object.values(months)}
              current={date.month}
              setCurrent={(newValue) => setDate({ ...date, month: newValue })}
            />
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                {daysOfWeek.map((dayOfWeek) => (
                  <th key={dayOfWeek}>
                    <SPText variant="comment-text">{dayOfWeek}</SPText>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, index) => (
                <tr key={index}>
                  {week.map((day, i) => (
                    <td key={i}>
                      {day ? (
                        <SPText>
                          <span
                            className={`${
                              getDateNumber(day) === date.day
                                ? styles["selected-date"]
                                : ""
                            }`}
                            onClick={() => {
                              setDate({ ...date, day: getDateNumber(day) });
                            }}
                          >
                            {day}
                          </span>
                        </SPText>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default forwardRef(SPDatePicker);
