import React from "react";
import SPText from "../../../SPText/SPText";
import Badge from "../../../Badge/Badge";
import SPButton from "../../../SPButton/SPButton";
import { TrashIcon, EyeBlindIcon } from "../../../icons";
import styles from "./Head.module.css";

export default function Head({
  user,
  marginBottom,
  visible = false,
  style = {},
  onClickDeleteButton,
  ...props
}) {
  const hide = (str) => (str ? "*".repeat(str.length) : str);
  return (
    <div
      className={styles.container}
      style={{ ...style, marginBottom }}
      {...props}
    >
      <div className={styles.left}>
        <div>
          {visible ? (
            <img
              src={user.profilePictureSmall}
              className={styles.profile}
              alt=""
            />
          ) : (
            <div className={styles.hidden}>
              <EyeBlindIcon color="#FFECE1" size={45} />
            </div>
          )}
        </div>
        <div className={styles.details}>
          <div>
            <SPText variant="event-club">
              {visible
                ? `${user.firstname} ${user.lastname}`
                : `${hide(user.firstname)} ${hide(user.lastname)}`}
            </SPText>
            <SPText variant="selection-text" color="#ABABAB">
              {visible ? user.id : hide(user.id)}
            </SPText>
          </div>
          <div>
            <Badge
              variant="long"
              title={user.status}
              color={
                user.status === "active"
                  ? "#67B36A"
                  : user.status === "suspended"
                  ? "#ABABAB"
                  : "#FF8A4A"
              }
            />
          </div>
        </div>
      </div>
      <SPButton
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 28 }}
        title="Felhasználó törlése"
        onClick={onClickDeleteButton}
      />
    </div>
  );
}
