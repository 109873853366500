import { useState } from "react";
import clubsApi from "../api/clubs";
import useApi from "./useApi";

function useClubs(filter, defaultPage = 0) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { request: getClubs } = useApi({
    call: clubsApi.getClubs,
    loading: [loading, setLoading],
    init: [initLoading, setInitLoading],
    initParams: {
      page: defaultPage,
      filterData: filter,
    },
    data: [data, setData],
  });

  return {
    initLoading,
    loading,
    clubs: data?.data,
    meta: data?.meta,
    getClubs,
  };
}

export default useClubs;
