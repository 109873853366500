import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SPText from "../SPText/SPText";
import styles from "./Header.module.css";
import SPButton from "../SPButton/SPButton";
import { ArrowSmallLeft } from "../icons";
import SPModal from "../SPModal/SPModal";

export default function Header({
  title,
  withModal,
  withBack,
  Action,
  className = "",
  backNavPath = null,
  backNavOptions = {},
}) {
  const { plain, highlighted } = title;
  const navigate = useNavigate();
  const handleBack = () => navigate(backNavPath || -1, backNavOptions);
  const [visible, setVisible] = useState(false);
  return (
    <div className={`${styles.container} ${className}`}>
      {withBack && (
        <SPButton
          variant="secondary"
          shape="icon"
          Icon={ArrowSmallLeft}
          onClick={() => (withModal ? setVisible(true) : handleBack())}
          className={styles.backButton}
        />
      )}
      <div className={styles["text-container"]}>
        <SPText
          variant="h1"
          color="#333333"
          style={{ fontSize: "40px", fontWeight: 800 }}
        >
          {`${plain}`}
        </SPText>
        <SPText
          variant="h1"
          color="#EB3318"
          style={{ fontSize: "40px", fontWeight: 800 }}
        >
          {highlighted}
        </SPText>
      </div>
      {Action && Action}

      {withModal && (
        <SPModal visible={visible} onCancel={() => setVisible(false)}>
          <SPText marginBottom={24} variant="h1" textAlign={"center"}>
            Biztosan visszalépsz?
          </SPText>
          <SPText
            marginBottom={24}
            variant="selection-text"
            textAlign={"center"}
          >
            Visszalépés után elvesznek a módosításaid.
          </SPText>
          <SPButton
            title="Megerősítés"
            variant="primary"
            onClick={handleBack}
          />
        </SPModal>
      )}
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.shape({
    plain: PropTypes.string,
    highlighted: PropTypes.string,
  }),
  withBack: PropTypes.bool,
  Action: PropTypes.element,
};
