import { useEffect, useState } from "react";
import metricsApi from "../api/metrics";

function useMetrics() {
  const [loading, setLoading] = useState(true);
  const [eventsFuture, setEventsFuture] = useState(null);
  const [eventsPast, setEventsPast] = useState(null);
  const [usersActive, setUsersActive] = useState(null);

  const getMetrics = async () => {
    setLoading(true);
    const res = await metricsApi.getMetrics();
    if (res.ok) {
        setEventsFuture(res.data.eventsFuture);
        setEventsPast(res.data.eventsPast);
        setUsersActive(res.data.usersActive);
    } else {
      console.log("[useMetrics, getMetrics] error getting the metrics");
    }
    setLoading(false);
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return { loading, eventsFuture, eventsPast, usersActive, getMetrics };
}

export default useMetrics;
