import React from "react";
import PropTypes from "prop-types";

import { CheckMarkIcon } from "../icons";
import SPText from "../SPText/SPText";
import styles from "./SPCheckbox.module.css";

export default function SPCheckbox({
  value,
  onChange,
  label,
  className = "",
  ...props
}) {
  return (
    <label className={`${styles.container} ${className}`}>
      <input
        type="checkbox"
        checked={value}
        onChange={() => onChange(!value)}
        {...props}
      />
      <div className={styles.checkmark}>
        <CheckMarkIcon size={15} />
      </div>
      <SPText variant="comment-text">{label}</SPText>
    </label>
  );
}

SPCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};
