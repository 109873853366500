import client, { requestConfig } from "./client";

const endpoint = "/sports";

const getSports = ({ page = 0, pageSize = 20, filterData = {} }) =>
  client.get(
    endpoint,
    {
      as: "admin",
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...filterData,
    },
    { headers: { ...requestConfig().headers } }
  );

const getSport = (id) =>
  client.get(
    `${endpoint}/${id}`,
    { as: "admin", view: "extended" },
    { headers: { ...requestConfig().headers } }
  );

const create = ({ name, status }) =>
  client.post(
    endpoint,
    { name, status },
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );

const update = ({ id, name, status }) =>
  client.patch(
    `${endpoint}/${id}`,
    { name, status },
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );

const deleteSport = (id) =>
  client.delete(
    `${endpoint}/${id}`,
    {},
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );

const uploadIcon = (sportId, formData) =>
  client.post(`${endpoint}/${sportId}/icon`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...requestConfig().headers,
    },
  });

const uploadCovers = (sportId, formData) =>
  client.post(`${endpoint}/${sportId}/cover-images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...requestConfig().headers,
    },
  });

const deleteSportCover = (sportId, coverId) =>
  client.delete(
    `${endpoint}/${sportId}/cover-images/${coverId}`,
    {},
    {
      headers: {
        ...requestConfig().headers,
      },
    }
  );

export default {
  getSports,
  getSport,
  deleteSport,
  create,
  update,
  uploadIcon,
  uploadCovers,
  deleteSportCover,
};
