import { useState } from "react";
import sportsApi from "../api/sports";
import useApi from "./useApi";

function useSports(filter, defaultPage = 0) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { request: getSports } = useApi({
    call: sportsApi.getSports,
    loading: [loading, setLoading],
    init: [initLoading, setInitLoading],
    initParams: {
      page: defaultPage,
      filterData: filter,
    },
    data: [data, setData],
  });

  return {
    initLoading,
    loading,
    sports: data?.data,
    meta: data?.meta,
    getSports,
  };
}

export default useSports;
