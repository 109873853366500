import { useEffect, useRef, useState } from "react";

function useQuery({ request, query, timeout }) {
  const [queryChanging, setQueryChanging] = useState(false);
  const nextQuery = useRef(null);

  const runQuery = async (_query) => {
    nextQuery.current = _query;
    setTimeout(async () => {
      if (nextQuery.current === _query) {
        await request({ nextQuery: nextQuery.current });
        setQueryChanging(false);
      } else {
        setQueryChanging(true);
      }
    }, timeout);
  };

  useEffect(() => {
    setQueryChanging(true);
    runQuery(query);
  }, [query]);

  return {
    query,
    queryChanging,
    runQuery,
  };
}

export default useQuery;
