import React from "react";
import PropTypes from "prop-types";
import styles from "./Card.module.css";

export default function Card({ Header, children, className = "", style = {} }) {
  return (
    <div className={`${styles.card} ${className}`} style={style}>
      {Header && <Header />}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Card.propTypes = {
  Header: PropTypes.elementType,
  children: PropTypes.any,
};
