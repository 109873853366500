export default function CommentIcon({
  size = 24,
  color = "#CCCCCC",
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1577_3971)">
        <path
          d="M24 11.7471C23.8553 9.4071 23.0291 7.16059 21.6232 5.2845C20.2172 3.40841 18.293 1.98477 16.0877 1.18907C13.8824 0.393373 11.4925 0.260409 9.21253 0.806571C6.93259 1.35273 4.86231 2.55415 3.25692 4.26269C1.65153 5.97124 0.58121 8.11224 0.177911 10.4217C-0.225388 12.7312 0.0559655 15.1083 0.987285 17.2598C1.9186 19.4113 3.45918 21.2433 5.41908 22.5298C7.37898 23.8164 9.67253 24.5013 12.017 24.5001H19C20.3256 24.4988 21.5966 23.9715 22.534 23.0341C23.4714 22.0968 23.9986 20.8258 24 19.5001V11.7471ZM22 19.5001C22 20.2957 21.6839 21.0588 21.1213 21.6214C20.5587 22.184 19.7956 22.5001 19 22.5001H12.017C10.6059 22.4994 9.21081 22.2014 7.92267 21.6254C6.63452 21.0494 5.48224 20.2083 4.54097 19.1571C3.59514 18.1063 2.8849 16.8655 2.45786 15.5179C2.03083 14.1702 1.89687 12.7468 2.06497 11.3431C2.3304 9.12902 3.32516 7.06626 4.89241 5.48C6.45966 3.89374 8.51027 2.87419 10.721 2.58207C11.1522 2.52803 11.5864 2.50064 12.021 2.50007C14.3514 2.49372 16.6098 3.30798 18.4 4.80008C19.4455 5.66902 20.3037 6.74123 20.9225 7.95171C21.5413 9.16218 21.9079 10.4857 22 11.8421V19.5001Z"
          fill={color}
        />
        <path
          d="M8 9.5H12C12.2652 9.5 12.5196 9.39464 12.7071 9.20711C12.8946 9.01957 13 8.76522 13 8.5C13 8.23478 12.8946 7.98043 12.7071 7.79289C12.5196 7.60536 12.2652 7.5 12 7.5H8C7.73478 7.5 7.48043 7.60536 7.29289 7.79289C7.10536 7.98043 7 8.23478 7 8.5C7 8.76522 7.10536 9.01957 7.29289 9.20711C7.48043 9.39464 7.73478 9.5 8 9.5Z"
          fill={color}
        />
        <path
          d="M16 11.5H8C7.73478 11.5 7.48043 11.6054 7.29289 11.7929C7.10536 11.9804 7 12.2348 7 12.5C7 12.7652 7.10536 13.0196 7.29289 13.2071C7.48043 13.3946 7.73478 13.5 8 13.5H16C16.2652 13.5 16.5196 13.3946 16.7071 13.2071C16.8946 13.0196 17 12.7652 17 12.5C17 12.2348 16.8946 11.9804 16.7071 11.7929C16.5196 11.6054 16.2652 11.5 16 11.5Z"
          fill={color}
        />
        <path
          d="M16 15.5H8C7.73478 15.5 7.48043 15.6054 7.29289 15.7929C7.10536 15.9804 7 16.2348 7 16.5C7 16.7652 7.10536 17.0196 7.29289 17.2071C7.48043 17.3946 7.73478 17.5 8 17.5H16C16.2652 17.5 16.5196 17.3946 16.7071 17.2071C16.8946 17.0196 17 16.7652 17 16.5C17 16.2348 16.8946 15.9804 16.7071 15.7929C16.5196 15.6054 16.2652 15.5 16 15.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1577_3971">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
