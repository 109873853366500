import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRoutes, AuthRoutes } from "./routes";
import useAuthContext, { AuthContext } from "./context/auth";
import client from "./api/client";

function App() {
  const { auth, authContext } = useAuthContext();

  const unauthenticatedMonitor = (response) => {
    if (response.status === 401) {
      authContext.signOut();
    }
  };

  useEffect(() => {
    client.addMonitor(unauthenticatedMonitor);
  }, []);

  if (auth.initLoading) return <div></div>;

  return (
    <>
      <BrowserRouter>
        <AuthContext.Provider value={{ auth, ...authContext }}>
          {!auth.token ? <AuthRoutes /> : <AppRoutes />}
        </AuthContext.Provider>
      </BrowserRouter>
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
