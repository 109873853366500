import client, { requestConfig } from "./client";

const endpoint = "/metrics";

const getMetrics = () =>
  client.get(
    endpoint,
    {},
    { headers: { ...requestConfig().headers } }
  );

export default {
  getMetrics,
};
  