import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card/Card";
import SPText from "../../../SPText/SPText";
import { UserIcon } from "../../../icons";

export default function ActiveUsersCard({ users, ...props }) {
  const Header = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "19px",
        width: "100%",
      }}
      {...props}
    >
      <UserIcon size={18} />
      <SPText variant="event-club">Aktív felhasználók</SPText>
    </div>
  );
  return (
    <Card Header={Header}>
      <SPText variant="h1" color="#FF8A4A" textAlign="center">
        {users || '-'}
      </SPText>
    </Card>
  );
}

ActiveUsersCard.propTypes = {
  activeUsers: PropTypes.number,
};
