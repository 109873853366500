import { create } from "apisauce";

const client = create({
  baseURL: process.env.REACT_APP_DEV_URL,
});

const requestConfig = () => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  return {
    headers,
  };
};

export default client;
export { requestConfig };
