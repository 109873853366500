import React, { createRef, useState, forwardRef } from 'react';
import SPText from '../SPText/SPText';
import styles from './SPTextArea.module.css';

function SPTextArea({
    marginBottom,
    label,
    onBlur,
    width = 600,
    ...props
}, ref) {
    const inputRef = createRef(ref);
    const [focused, setFocused] = useState(ref?.current?.onfocus);
    return (
        <div>
            {label && (
                <SPText marginBottom={8} variant="h3">
                {label}
                </SPText>
            )}
            <div
                className={`${styles.container} ${focused && styles.focus}`}
                style={{ marginBottom, position: "relative" }}
                onMouseDown={(e) => {
                e.preventDefault();
                inputRef?.current?.focus();
                }}
            >
                <textarea
                    className={styles.input}
                    style={{ width }}
                    // wrapper onMouseDown event should not affect the input onMouseDown event
                    onMouseDown={(e) => e.stopPropagation()}
                    onBlur={(e) => {
                    onBlur && onBlur(e);
                    setFocused(false);
                    }}
                    onFocus={() => setFocused(true)}
                    ref={inputRef}
                    {...props}
                ></textarea>
            </div>
        </div>
    );
}

export default forwardRef(SPTextArea);
