import React from "react";
import SPText from "../../../SPText/SPText";
import Badge from "../../../Badge/Badge";
import SPButton from "../../../SPButton/SPButton";
import SPCheckbox from "../../../SPCheckbox/SPCheckbox";
import { TrashIcon } from "../../../icons";
import styles from "./Head.module.css";

export default function Head({
  event,
  marginBottom,
  style = {},
  onClickDeleteButton,
  featured,
  setFeatured,
  ...props
}) {
  return (
    <div
      className={styles.container}
      style={{ ...style, marginBottom }}
      {...props}
    >
      <div className={styles.left}>
        <div>
          <img src={event.cover} className={styles.picture} alt="" />
        </div>
        <div className={styles.details}>
          <div style={{ width: "100%" }}>
            <SPCheckbox
              label="Kiemelt esemény"
              value={featured}
              onChange={setFeatured}
            />
            <SPText variant="event-club" className={styles.title}>
              {event.name}
            </SPText>
            <SPText variant="selection-text" color="#ABABAB">
              {event.id}
            </SPText>
          </div>
          <div>
            <Badge
              variant="long"
              title={event.visibility}
              color={event.visibility === "private" ? "#006C8D" : "#67B36A"}
              marginTop={8}
            />
          </div>
        </div>
      </div>
      <SPButton
        variant="secondary"
        Icon={TrashIcon}
        IconProps={{ size: 28 }}
        title="Esemény törlése"
        onClick={onClickDeleteButton}
      />
    </div>
  );
}
