import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowSmallRight, SearchIcon, TrashIcon } from "../../components/icons";
import {
  Header,
  SPButton,
  SPCheckbox,
  SPInput,
  SPModal,
  SPTable,
  SPText,
} from "../../components/index";
import useQuery from "../../hooks/useQuery";
import useToast from "../../hooks/useToast";
import useUsers from "../../hooks/useUsers";
import styles from "./UsersPage.module.css";

function UsersPage() {
  const { deleteError, deleteSuccess } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(location?.state?.search || "");
  const { initLoading, loading, users, meta, getUsers, deleteUser } = useUsers(
    { s: search || null },
    location?.state?.page || 0
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [displayUsers, setDisplayUsers] = useState(null);
  const [showInformation, setShowInformation] = useState(
    location?.state?.infoVisible || false
  );

  const { queryChanging } = useQuery({
    query: search,
    timeout: 500,
    request: ({ nextQuery }) =>
      users && getUsers({ page: 0, filterData: { s: nextQuery || null } }),
  });

  const deleteUserHandler = async () => {
    if (!selectedUserId) return;
    await deleteUser({
      onSuccess: () => {
        deleteSuccess();
        setSelectedUserId(null);
        setShowModal(false);
        getUsers({ page: meta.page, filterData: { s: search || null } });
      },
      onError: () => deleteError(),
      id: selectedUserId,
    });
  };

  function hideUserInformation(user) {
    return { ...user, email: "*".repeat(user.email.length) };
  }

  const columns = [
    { title: "Azonosító", field: "id" },
    { title: "E-mail cím", field: "email" },
    {
      title: "Regisztráció dátuma",
      field: "createdAt",
      Component: ({ column }) => (
        <SPText variant="selection-text">
          {dayjs(column).format("YYYY.MM.DD.")}
        </SPText>
      ),
    },
  ];

  const Action = ({ item, ...props }) => (
    <div className={styles.actionContainer} {...props}>
      <SPButton
        variant="secondary"
        shape="icon"
        Icon={TrashIcon}
        IconProps={{ size: 22 }}
        onClick={() => {
          setSelectedUserId(item.id);
          setShowModal(true);
        }}
      />
      <SPButton
        shape="icon"
        Icon={ArrowSmallRight}
        IconProps={{ size: 12 }}
        onClick={() =>
          navigate(`/users/${item.id}/`, {
            state: { infoVisible: showInformation, page: meta?.page, search },
          })
        }
      />
    </div>
  );

  useEffect(() => {
    if (!users) return;
    if (!showInformation) {
      const display = [...users].map((user) => hideUserInformation(user));
      console.log(display);
      setDisplayUsers([...display]);
    } else {
      setDisplayUsers(users);
    }
  }, [showInformation, users, search]);

  if (initLoading) return <div></div>;

  return (
    <div className={styles.container}>
      <SPModal
        visible={showModal}
        onCancel={() => {
          setSelectedUserId(null);
          setShowModal(false);
        }}
      >
        <SPText variant="h1" textAlign="center">
          Felhasználó törlése
        </SPText>
        <SPText variant="selection-text">
          Törlés után ez a felhasználó nem lesz elérhető az applikációban, és új
          fiókkal tud majd csak regisztrálni.
        </SPText>
        <SPButton
          disabled={loading}
          title="törlés"
          onClick={deleteUserHandler}
        />
      </SPModal>

      <Header title={{ plain: "Felhasználók", highlighted: "listája" }} />
      <div className={styles.filterContainer}>
        <SPCheckbox
          label="Felhasználói adatok láthatósága"
          value={showInformation}
          onChange={setShowInformation}
        />
        <SPInput
          placeholder="Felhasználó ID, e-mail cím..."
          PrimaryIcon={!queryChanging && SearchIcon}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>
      {loading && <div>Loading...</div>}
      {!loading && displayUsers && (
        <div>
          <SPTable
            items={displayUsers}
            columns={columns}
            meta={meta}
            Action={Action}
            onPageChange={(page) =>
              getUsers({ page, filterData: { s: search || null } })
            }
          />
        </div>
      )}
    </div>
  );
}

export default UsersPage;
